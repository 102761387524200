export const schemas = {
        'schemaIp': {
            pattern: /^(\d*)$/,
            min: 2,
            max: 254
        },
        'schemaLanPort': {
            pattern: /^(\d*)$/,
            min: 1,
            max: 65535
        },
        ​'schemaWanPort': {
            pattern: /^(\d*)$/,
            min: 1,
            max: 65535
        },
        'schemaURL': {
            type: 'string',
            pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-_\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/
        },

        'schemaURLExceptions': {
            type: 'string',
            pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-_\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/
        },
        'schemaNccURLExceptions': {
            type: 'string',
            pattern: /^[a-z0-9]+([\-_\.]{1}[a-z0-9]+)*\.[a-z]{2,10}?(!\/.*)?$/
        },
        'schemaHistoryEventId': {
            type: 'string',
            pattern: /^[a-f0-9]{24}$/i
        },​
        'schemaHash': {
            type: 'string',
            pattern: /^[a-f0-9]{32}$/i
        },
    ​
        'schemaUuid': {
            type: 'string',
            pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
            minLength: 1,
            description: 'UUID Validator'
        },

        'schemasPasswordContains': {
            type: 'string',
            minLength: 8,
            maxLength: 31,
            'numbers': {
                type: 'string',
                pattern: /(?=.*[0-9])/
            },
            'upperCharacters': {
                type: 'string',
                pattern: /(?=.*[A-Z])/
            },
            'lowerCharacters': {
                type: 'string',
                pattern: /(?=.*[a-z])/
            },
            'symbols': {
                type: 'string',
                pattern: /(?=.*[\!\@\#\$\%\^\&\*\(\)\_\+\|\~\\\-\=\`\{\}\[\]\:\"\;\'<\>\?\,\.\/])/
            }
        },

        'schemasCodeRulesAndroid': {
            type: 'string',
            minLength: 4,
            maxLength: 4,
            pattern: /^[0-9]*$/
        },

        'schemasPasswordRulesWindows': {
            type: 'string',
            minLength: 8,
            maxLength: 254,
            'numbers': {
                type: 'string',
                pattern: /(?=.*[0-9])/
            },
            'upperCase': {
                type: 'string',
                pattern: /(?=.*[A-Z])/
            },
            'lowerCase': {
                type: 'string',
                pattern: /(?=.*[a-z])/
            },
        },
    ​
        'schemaToken': {
            type: 'string',
            pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
            description: 'Token Validator'
        },
    ​
        'schemaPin': {
            type: 'string',
            pattern: /^(\d{4}|\d{6})$/
        },
    ​
        'schemaListProfilesResponse': {
            type: 'object',
            properties: {
                'profile_id': {
                    type: 'string'
                },
                'first_name': {
                    type: 'string'
                },
                'email': {
                    type: 'string'
                },
                'gender': {
                    type: 'string'
                },
                'last_name': {
                    type: 'string'
                },
                'phone': {
                    type: 'string'
                },
                'profile_created': {
                    type: 'integer'
                },
                'profile_modified': {
                    type: 'integer'
                },
                'profile_pic': {
                    type: 'string'
                }
            },
            required: [
                'profile_id',
                'first_name'
            ]
        },
    ​
        'schemaListSubscriptionResponse': {
            type: 'object',
            properties: {
                'app_id': {
                    type: 'string'
                },
                'type': {
                    type: 'string'
                },
                'bundle_id': {
                    type: 'string'
                },
                'commercial_id': {
                    type: 'string'
                },
                'expiry': {
                    type: 'integer'
                },
                'last_update': {
                    type: 'integer'
                },
                'validity': {
                    type: 'integer'
                },
                'life_cycle': {
                    type: 'string'
                },
                'devices': {
                    type: 'integer'
                },
                'active_devices': {
                    type: 'integer'
                },
                'active_device_ids': {
                    type: 'array'
                },
                'server_time': {
                    type: 'integer'
                }
            },
            required: [
                'app_id',
                'type',
                'bundle_id',
                'commercial_id',
                'expiry',
                'last_update',
                'life_cycle',
                'devices',
                'active_devices',
                'active_device_ids',
                'server_time'
            ]
        },
    ​
        'schemaListDevicesResponse': {
            type: 'object',
            properties: {
                'agent_status': {
                    type: 'integer'
                },
                'box_managed': {
                    type: 'integer'
                },
                'box_detected_name': {
                    type: 'string'
                },
                'created': {
                    type: 'integer'
                },
                'device_model': {
                    type: 'string'
                },
                'device_os': {
                    type: 'string'
                },
                'device_type': {
                    type: 'string'
                },
                'friendly_name': {
                    type: 'string'
                },
                'last_seen': {
                    type: 'integer'
                },
                'macs': {
                    type: 'array'
                },
                'device_id': {
                    type: 'string'
                },
                'install_agent': {
                    type: 'integer'
                }
            },
            required: [
                'agent_status',
                'box_managed',
                'created',
                'device_os',
                'device_type',
                'device_id',
                'install_agent'
            ]
        },
    ​
        'schemaSERVICE': {
            type: 'string'
        },
    ​
        'schemaMETHOD': {
            type: 'string',
            pattern: /^(GET|POST)$/
        },
    ​
        'schemaJSON': {
            type: 'object'
        },
    ​
        'schemaJSONorARRAY': {
            type: [
                'object',
                'array'
            ]
        },
    ​
        'schemaNAME': {
            type: 'string',
            pattern: /^(?!((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?))[^<>_\\/%$^&]*$/,
            minLength: 2,
            maxLength: 50
        },

        'schemaNAME_Acc': {
            type: 'string',
            pattern: /^(?!((https?:\/\/){1}([\da-z.-]+).([a-z.]{2,6})([/\w.-]*)*\/?))[^<>_\\/%$^&]*$/,
            maxLength: 50
        },
    ​
        'schemaNAME_m': {
            type: 'string',
            pattern: /^(?!\s+$)(?!((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?))[^<>_\\/%$^&]*$/,
            minLength: 1,
            maxLength: 50
        },
    ​
        'schemaFULLNAME': {
            type: 'string',
            maxLength: 100,
            minLength: 3,
            pattern: /^(?!((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?))[^<>_\\/%$^&]*$/
        },
    ​
        'schemaPHONE': {
            type: 'string',
            maxLength: 50,
            pattern: /^[0-9()\-+\/.]+$/
        },
    ​
        // * dd/mm/yyyy, dd-mm-yyyy or dd.mm.yyyy and leap years
        'schemaDATE': {
            type: 'string',
            pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
        },
    ​
        'schemaCITY': {
            type: "string",
            minLength: 3,
            maxLength: 100,
            pattern:/^(?!((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?))[^<>_\\/%$^&]*$/
        },

        'schemaADDRESS': {
            type: "string",
            maxLength: 100,
            pattern:/^(?!((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?))[^<>_\\/%$^&]*$/
        },

        'schemaNOEMPTYSPACES': {
            type: "string",
            pattern: /(?!^\s+$)^.*$/m
        },

        'schemaBillingDetails': {
            type: 'string',
            pattern: /(?!-)^.*$/m
        },

        'schemaZipCode': {
            type: 'string',
            maxLength: 17,
            minLength: 3,
            pattern: /^[0-9]{3,9}([0-9,A-Z, -]{1,8})?$/
        },

        'schemaPASS': {
            type: 'string',
            minLength: 8,
            maxLength: 254
        },

        'schemaEMAIL': {
            type: 'string',
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))*$/i
        },

        // 4 - 8 digits
        'schemaPIN': {
            type: 'string',
            pattern: /^[0-9]{4,8}$/
        },

        // more than 1 digit followed by ':', followed by one digit
        // timestam:step (step >= 1, step <= 6)
        'schemaBannerSetting': {
            type: 'string',
            pattern: /^\d+\:\d/
        },

        'schemaONBOARDING_DIP': {
            /* nu stiu daca se mai folosesc, momentan in cod e folosit schemaNAME*/
            // 'nameRegex': {
            //     type: 'string',
            //     pattern: /^[a-zA-Z\-' ]{2,100}$/
            // },
            // 'middleNameRegex': {
            //     type: 'string',
            //     pattern: /^[a-zA-Z\-' ]{1,100}$/
            // },
            'prefix': {
                type: 'string',
                pattern: /^[0-9\-\+]{1,6}$/
            },
            'phoneNumber': {
                type: 'string',
                pattern: /^[0-9]{2,15}$/
            },
            'codeMobile': {
                type: 'string',
                pattern: /^[a-zA-Z0-9]{6}$/
            },
            'codeLandline': {
                type: 'string',
                pattern: /^[a-zA-Z0-9]{4}$/
            },
            'email': {
                type: 'string',
                pattern: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                maxLength: 100
            }
        },

        'schemaREDEEM': {
            type: 'string',
            pattern: /^[a-zA-Z0-9]{10}$/,
            minLength: 1
        },

        'schemaLevelTwoRedeem': {
            type: 'string',
            pattern: /^[a-zA-Z0-9]{7}$/,
            minLength: 1
        },

        'schemaIdSearchUrl': {
            type: 'string',
            pattern: /^[0-9]{10}$/
        },

        'schemaCreateProfile': {
            type: 'object',
            properties: {
                'first_name': {
                    type: 'string',
                    pattern: /^(?!((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?))[^<>_\\/%$^&]*$/,
                    maxLength: 50,
                    minLength: 2
                },
                'email': {
                    type: 'string',
                    pattern: /^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i
                },
                'birthday': {
                    type: 'number'
                },
                'phone': {
                    type: 'string',
                    maxLength: 50,
                    pattern: /^[0-9()\-+\/.]+$/
                },
                'gender': {
                    type: 'string',
                    minLength: 1,
                    maxLength: 1,
                    pattern: /^[umf]/i
                },
                'profile_created': {
                    type: 'string'
                }
            },
            required: [
                'first_name'
            ]
        },

        'schemaEditProfile': {
            type: 'object',
            properties: {
                'first_name': {
                    type: 'string',
                    pattern: /^(?!((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?))[^<>_\\/%$^&]*$/,
                    maxLength: 50,
                    minLength: 1
                },
                'email': {
                    type: 'string',
                    pattern: /^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i
                },
                'birthday': {
                    type: 'number'
                },
                'phone': {
                    type: 'string',
                    maxLength: 50,
                    pattern: /^[0-9()\-+\/.]+$/
                },
                'gender': {
                    type: 'string',
                    minLength: 1,
                    maxLength: 1,
                    pattern: /^[umf]/i
                },
                'profile_created': {
                    type: 'string'
                }
            }
        },
    ​
        'schemaProfileSetActive': {
            type: 'object',
            properties: {
                'device_id': {
                    type: 'string'
                },
                'profile_id': {
                    type: 'string'
                },
                'device_account_sid': {
                    type: 'string'
                }
            },
            required: [
                'profile_id',
                'device_id'
            ]
        },
    ​
        'schemaConnectLogin': {
            type: 'object',
            properties: {
                'user_token': {
                    type: 'string'
                },
                'type': {
                    type: 'string'
                }
            },
            required: [
                'user_token'
            ]
        },
    ​
        'schemaSendMessageAlert': {
            type: 'object',
            properties: {
                'deviceId': {
                    type: 'string',
                    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
                },
                'appId': {
                    type: 'string',
                },
                'message': {
                    type: 'string'
                },
                'alert': {
                    type: 'number'
                }
            },
            required: [
                'deviceId',
                'appId',
            ]
        },

        'schemaSendLock': {
            type: 'object',
            properties: {
                'deviceId': {
                    type: 'string',
                    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
                },
                'appId': {
                    type: 'string',
                },
                'pin': {
                    type: 'string'
                },
                'snap': {
                    type: 'number'
                }
            },
            required: [
                'deviceId',
                'appId',
                'pin'
            ]
        },
    ​
        'schemaListSubmitTicket': {
            type: 'object',
            properties: {
                'subject': {
                    type: 'string'
                },
                'message': {
                    type: 'string'
                },
                'person_name': {
                    type: 'string'
                },
                'department_id': {
                    type: 'number'
                },
                'product_id': {
                    type: 'number'
                },
                'country': {
                    type: 'number'
                },
                'os': {
                    type: 'number'
                },
                'category_id': {
                    type: 'number'
                },
                'subscription_info': {
                    type: 'string'
                },
                'language_id': {
                    type: 'number'
                },
                'person_email': {
                    type: 'string'
                },
                'refund_reason': {
                    type: 'number'
                },
                'second_category': {
                    type: 'object'
                },
                'katastif_id': {
                    type: ['array', 'string']
                }
            },
            required: [
                'category_id',
                'department_id',
                'language_id',
                'message',
                'os',
                'person_name',
                'product_id',
                'subject'
            ]
        },
    ​
        'schemsSettingsPassword': {
            type: 'object',
            properties: {
                'status': {
                    type: 'string'
                },
                'pass': {
                    type: 'string',
                    minLength: 8,
                    maxLength: 31,
                }
            },
            required: [ 
                'status' 
            ]
        },
    ​
        'schemasSchedule': {
            type: 'object',
            properties: {
                'name': {
                    type: 'string'
                },
                'phone': {
                    type: 'string',
                    maxLength: 50,
                    pattern: /^[0-9()\-+\/.]+$/
                },
                'dif': {
                    type: 'number'
                },
                'app_id': {
                    type: 'string',
                },
                'minutes': {
                    type: 'string'
                },
                'hour': {
                    type: 'string'
                },
                'schedule': {
                    type: 'string'
                },
                'country': {
                    type: 'string'
                },
                'lang': {
                    type: 'string'
                }
            },
            required: [
                'name',
                'phone',
                'dif',
                'app_id',
                'minutes',
                'hour',
                'schedule',
                'country',
                'lang'
            ]
        },
    ​
        'schemasProfileArea': {
            type: 'string',
            minLength: 3,
            // pattern matches at least 2 non-whitespace characters and at least 3 characters in total
            pattern: /^(.*)([^\s]{1,})(.{1,})([^\s]{1,})(.*)$/
        },
    ​
        'schemaAppStats': {
            type: 'object',
            properties: {
                'dailyStartTime': {
                    type: 'number'
                },
                'dailyEndTime': {
                    type: 'number'
                },
                'profile_id': {
                    type: 'string',
                    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
                },
                'limitDevices': {
                    type: 'number',
                },
                'skipDevices': {
                    type: 'number'
                },
                'aggregatedStartTime': {
                    type: 'number'
                },
                'aggregatedEndTime': {
                    type: 'number'
                }
            },
            required: [ 
                'dailyStartTime', 
                'dailyEndTime', 
                'profile_id',
                'limitDevices', 
                'skipDevices', 
                'aggregatedStartTime', 
                'aggregatedEndTime'
            ]
        },
    ​
        'schemasEditSchedule': {
            type: 'object',
            properties: {
                'active': {
                    type: 'boolean'
                },
                'label': {
                    type: 'string',
                },
                'schedule': {
                    type: 'array'
                },
                'schedule_id': {
                    type: 'string',
                    pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                }
            },
            required: [ 
                'active'
            ]
        },
    ​
        'schemaParentalScheduleName': {
            type: 'string',
            pattern: /^(?!((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?))[^<>_\\/%$^&]*$/,
            maxLength: 20,
            minLength: 1
        },
    ​
        'schemaParentalChildName': {
            type: 'string',
            pattern: /^(?!((https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?))[^<>_\\/%$^&]*$/,
            maxLength: 50,
            minLength: 2
        },
    ​
    };
