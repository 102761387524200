// External
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

// Internal
import { ValuesService } from '../../../values/values.service';
import { ConnectMgmtService } from '../../requests/connect-mgmt-service/connect-mgmt.service';
import { AppConfigurationStatus } from '../../../models/Services.model';

@Injectable({
    providedIn: 'root'
})

export class IdTheftProtectionService {

    private readonly onListEnrollmentStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdateEnrollmentStatus = true;

    private userEnrolled: boolean = false;
    private appConfigurationStatus: AppConfigurationStatus = AppConfigurationStatus.PENDING;

    constructor(
        private readonly valuesService: ValuesService,
        private readonly connectMgmtService: ConnectMgmtService
    ) { }

    /**
     * List Enrollment Status for IdTheft
     * @public
     * @memberof IdTheftProtectionService
     * @return {*} {Observable<any>}
     */
    public listEnrollmentStatus(): Observable<any> {
        if (!this.markToUpdateEnrollmentStatus){
            return of(this.userEnrolled);
        }

        if (this.onListEnrollmentStatus$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListEnrollmentStatus$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onListEnrollmentStatus$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectMgmtService.listEnrollmentStatus()
            .pipe(
                map(resp => {
                    this.userEnrolled = resp.enrollment_status;
                    this.appConfigurationStatus = resp.enrollment_status ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
                    this.markToUpdateEnrollmentStatus = false;
                    this.onListEnrollmentStatus$.next(this.valuesService.processServiceState.DONE);
                    return of(true);
                }),
                catchError(err => {
                    this.appConfigurationStatus = AppConfigurationStatus.NOT_CONFIGURED
                    this.markToUpdateEnrollmentStatus = true;
                    this.onListEnrollmentStatus$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                })
            );
        }
    }

    /**
     * Get Enrollment Status for IdTheft
     * @public
     * @memberof IdTheftProtectionService
     * @return {boolean} True if the user is enrolled, false otherwise
     */
    public getEnrollmentStatus(): boolean {
        return this.userEnrolled;
    }

    /**
     * Get Configuration status for IdTheft App
     * @public
     * @memberof IdTheftProtectionService
     * @return {AppConfigurationStatus} CONFIGURED if user is enrolled, NOT_CONFIGURED otherwise
     */
    public getConfigurationStatus(): AppConfigurationStatus {
        return this.appConfigurationStatus;
    }
}
