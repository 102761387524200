// External
import { Injectable } from '@angular/core';

//  Internal
import { ThemeService } from '../services/core/theme.service';
import { Color } from '../services/interfaces/itheme/itheme.service';
import { ValuesService } from './values.service';
import { NotificationsSubtypes } from '../services/global/events/events.model';
import { RecommendationCardTypes } from '../../pages/dashboard/cards/recommendation-card/recommendation.model';

@Injectable({
    providedIn: 'root'
})

export class DashboardValuesService {

    constructor(
        private readonly valuesService: ValuesService,
        private readonly themeService: ThemeService
    ) {}

    readonly borderDash = 2;
    readonly backgroundColorAlphaValue = 0.05;

    readonly tooltipNoThreats = 3;
    readonly countNumber = 10;

    colors = {
        'box': [this.themeService.getVariableColor(Color.a4_15), this.themeService.getVariableColor(Color.primary)],
        'family': [this.themeService.getVariableColor(Color.primary),this.themeService.getVariableColor(Color.a4_15)]
    };

    chartStates = {
        ACTIVE: 'active',
        INACTIVE: 'inactive'
    };

    chartThemeables = {
        data: {
            [this.chartStates.ACTIVE]: {
                color: () => this.themeService.getColor(Color.primary),
                hoverBg: () => this.themeService.getColor(Color.light)
            },
            [this.chartStates.INACTIVE]: {
                color: () => this.themeService.getColor(Color.primary_10),
                hoverBg: () => this.themeService.getColor(Color.primary_10)
            }
        },
        ticks: {
            fontColor: () => this.themeService.getColor(Color.shade, 0.6)
        },
        gridLines: {
            color: () => this.themeService.getColor(Color.shade, 0.1),
            zeroLineColor: () => this.themeService.getColor(Color.shade, 0.1)
        },
        tooltip: {
            backgroundColor:  () => this.themeService.getColor(Color.primary_5),
            borderColor: () => this.themeService.getColor(Color.primary),
            titleFontColor:  () => this.themeService.getColor(Color.shade, 0.8),
            footerFontColor:  () => this.themeService.getColor(Color.shade, 0.6)
        }
    };

    chartStyle = {
        fontFamily: "'Roboto','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        fontSize: 14,

        ticks: {
            fontColor: this.chartThemeables.ticks.fontColor(),
            padding: 16,
            maxRotation: 0,
            autoSkipPadding: 16
        },
        gridLines: {
            color: this.chartThemeables.gridLines.color(),
            zeroLineColor: this.chartThemeables.gridLines.zeroLineColor(),
            drawTicks: true,
            drawBorder: false,
            tickMarkLength: 19,
            borderDash: [this.borderDash],
            zeroLineWidth: 1,
            zeroLineBorderDash: [1]
        },
        line: {
            borderWidth: 3,
            pointRadius: 4,
            pointHoverBorderWidth: 3,
            pointHoverRadius: 7,
            backgroundColor: this.themeService.getColor(Color.primary, this.backgroundColorAlphaValue),
            borderColor: this.themeService.getColor(Color.primary)
            // lineTension: 0.3
        },
        tooltip: {
            backgroundColor: this.chartThemeables.tooltip.backgroundColor(),
            borderColor: this.chartThemeables.tooltip.borderColor(),
            titleFontColor: this.chartThemeables.tooltip.titleFontColor(),
            footerFontColor: this.chartThemeables.tooltip.footerFontColor(),
            borderWidth: 1,
            titleFontStyle: 'bold',
            titleSpacing: 1,
            titleMarginBottom: 0,
            footerFontStyle: 'normal',
            footerMarginTop: 10,
            // footerSpacing: 1,
            xPadding: 12,
            yPadding: 12,
            displayColors: false,
            // yAlign: 'bottom',
            caretPadding: 5
        },
        lineLayput: {
            padding: {
                top: 30,
                bottom: 10
            }
        }
    };

    daysValue = {
        '7' : {
            value: 7,
            text: 'seven'
        },
        '30': {
            value: 30,
            text: 'thirty'
        }
    };

    daySeven = '7';
    dayThirty = '30';

    threatsColor = {
        'unknown': '#50A50F',
        'malware': '#DB9923',
        'phishing': '#21529C',
        'ransomware': '#C93553',
        'fraud': '#804495',
        'pua': '#D67251',
        'untrusted': '#2FC7BE',
        'exploit': '#D6E145',
        'attack': '#DFA063',
        'anomaly': '#E4CA3D',
        'privacy': '#3B868B',
        'bot': '#371D98',
        'certificate': '#D9443A',
        'spam': '#73C137',
        'miner': '#BE8622',
        'trojan': '#276ACD'
    };

    threadsDeatilsKeys = {
        confirmationStatus: 'dashboard.threats.details.confirmation.status',
        attackSource: 'dashboard.threats.details.attack.source',
        blockedUrl: 'dashboard.threats.details.blocked.url',
        typeThreatMalware: 'dashboard.threats.details.type.threat.malware',
        typeThreatMalicious: 'dashboard.threats.details.type.threat.malicious',
        notificatonDangerousUrl: 'notifications.dangerous_url.dangerous_url.title.malware',
        notificatonDangerousUrlNoDevice: 'notifications.dangerous_url.dangerous_url.noDevice.title.malware',
        externalIp: 'dashboard.threats.details.external.ip',
        localPort: 'dashboard.threats.details.local.port',
        bockedApp: 'dashboard.threats.details.blocked.app',
        notificationMaliciousUrl: 'notifications.malicious_url.malicious_url.title',
        notificationMaliciousUrlNoDevice: 'notifications.malicious_url.malicious_url.noDevice.title',
        typeThreatExploit: 'dashboard.threats.details.type.threat.exploit',
        typeThreatPrivacy: 'dashboard.threats.details.type.threat.privacy',
        blockedIp: 'dashboard.threats.details.blocked.ip',
        maliciousHostTitle: 'dashboard.threats.details.malicious_host.title',
        maliciousHostNoDeviceTitle: 'dashboard.threats.details.malicious_host.noDevice.title'
    };

    private readonly securityThreadsDeatilsKeys = {
        notificatonDangerousUrl: 'security.threats.dangerous.page.title',
        notificationMaliciousUrl: 'security.threats.custom.type.title',
        maliciousHostTitle: 'security.threats.details.malicious_host.title',
    };

    threatsDetailsModule = {
        'default': {
            'title': 'dashboard.threats.details.title.placeholder',
            'securityTitle': 'dashboard.threats.details.title.placeholder',
            'noDeviceTitle': 'dashboard.threats.details.noDevice.title.placeholder',
            'description': 'dashboard.threats.details.desc.placeholder',
            'confirmation': this.threadsDeatilsKeys.confirmationStatus,
            'threat_desc': this.threadsDeatilsKeys.attackSource,
            'threat_desc_port': 'dashboard.threats.details.port',
            'threat_desc_protocol': 'dashboard.threats.details.protocol',
            'showUrl': true,
            'showIp': true,
            'showPort': true,
            'showProtocol': true
        },
        'malicious_host' : {
            'box': {
                'default': {
                    'title': this.threadsDeatilsKeys.maliciousHostTitle,
                    'securityTitle': this.securityThreadsDeatilsKeys.maliciousHostTitle,
                    'noDeviceTitle': this.threadsDeatilsKeys.maliciousHostNoDeviceTitle,
                    'description': 'notifications.malicious_host.malicious_host.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threatTypeText': 'notifications.general.desc_threat.malicious_host',
                    'threat_desc': this.threadsDeatilsKeys.typeThreatMalicious,
                    'showUrl': true

                }
            }
        },
        'fraud': {
            'default': {
                'title': 'dashboard.threats.details.malicious_url.fraud.title',
                'securityTitle': 'security.threats.fraud.blocked.title',
                'noDeviceTitle': 'dashboard.threats.details.malicious_url.fraud.noDevice.title',
                'description': 'notifications.malicious_url.malicious_url.desc.fraud',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.fraud',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'box': {
                'default': {
                    'title': 'notifications.dangerous_url.dangerous_url.title.fraud',
                    'securityTitle': 'security.threats.fraud.page.blocked.title',
                    'noDeviceTitle': 'notifications.dangerous_url.dangerous_url.noDevice.title.fraud',
                    'description': 'notifications.dangerous_url.dangerous_url.desc.fraud',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': this.threadsDeatilsKeys.typeThreatMalware,
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                }
            },
            [NotificationsSubtypes.BMS]: {
                'title': this.threadsDeatilsKeys.maliciousHostTitle,
                'securityTitle': this.securityThreadsDeatilsKeys.maliciousHostTitle,
                'noDeviceTitle': this.threadsDeatilsKeys.maliciousHostNoDeviceTitle,
                'description': 'notifications.malicious_host.malicious_host.desc.fraud',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'notifications.general.desc_threat.fraud',
                'attack_type': this.threadsDeatilsKeys.typeThreatMalicious,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true,
                'showUrl': true
            }
        },
        'infected': {
            'box': {
                'default': {
                    'title': 'notifications.dangerous_url.dangerous_url.title.infected',
                    'securityTitle': 'security.threats.untrasted.page.blocked.title',
                    'noDeviceTitle': 'notifications.dangerous_url.dangerous_url.noDevice.title.infected',
                    'description': 'notifications.dangerous_url.dangerous_url.desc.infected',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': this.threadsDeatilsKeys.typeThreatMalware,
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                }
            }
        },
        'malware': {
            'default': {
                'title': this.threadsDeatilsKeys.notificatonDangerousUrl,
                'securityTitle': this.securityThreadsDeatilsKeys.notificatonDangerousUrl,
                'noDeviceTitle': this.threadsDeatilsKeys.notificatonDangerousUrlNoDevice,
                'description': 'notifications.dangerous_url.dangerous_url.desc.malware',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatMalware,
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },

            // BOX
            'box': {
                'default': {
                    'title': this.threadsDeatilsKeys.notificatonDangerousUrl,
                    'securityTitle': this.securityThreadsDeatilsKeys.notificatonDangerousUrl,
                    'noDeviceTitle': this.threadsDeatilsKeys.notificatonDangerousUrlNoDevice,
                    'description': 'notifications.dangerous_url.dangerous_url.desc.malware',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': this.threadsDeatilsKeys.typeThreatMalware,
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                },
                'trojan': {
                    'title': 'notifications.malware.trojan.title',
                    'securityTitle': 'security.malware.trojan.title',
                    'noDeviceTitle': 'notifications.malware.trojan.noDevice.title',
                    'description': 'notifications.malware.trojan.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.card.trojan',
                    'threat_desc': this.threadsDeatilsKeys.externalIp,
                    'threat_desc_extPort': 'dashboard.threats.details.external.port',
                    'showIp': true,
                    'showExtPort': true

                },
                'metasploit': {
                    'title': 'notifications.malware.metasploit.title',
                    'securityTitle': 'security.threats.box.exploit.attack.title',
                    'noDeviceTitle': 'notifications.malware.metasploit.noDevice.title',
                    'description': 'notifications.malware.metasploit.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.malware.metasploit',
                    'threat_desc': this.threadsDeatilsKeys.attackSource,
                    'threat_desc_port': this.threadsDeatilsKeys.localPort,
                    'threat_desc_extPort': 'dashboard.threats.details.external.port',
                    'showPort': true,
                    'showExtPort': true
                }
            },

            [NotificationsSubtypes.BMS]: {
                'title': this.threadsDeatilsKeys.maliciousHostTitle,
                'securityTitle': this.securityThreadsDeatilsKeys.maliciousHostTitle,
                'noDeviceTitle': this.threadsDeatilsKeys.maliciousHostNoDeviceTitle,
                'description': 'notifications.malicious_host.malicious_host.desc.malware',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'notifications.general.desc_threat.malware',
                'attack_type': this.threadsDeatilsKeys.typeThreatMalicious,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true,
                'showUrl': true
            },

            // CL
            'app': {
                'title': 'notifications.malicious_app.malicious_app.title',
                'securityTitle': 'security.threats.dangerous.app.title',
                'noDeviceTitle': 'notifications.malicious_app.malicious_app.noDevice.title',
                'description': 'notifications.malicious_app.malicious_app.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.malware.app',
                'threat_desc': this.threadsDeatilsKeys.bockedApp,
                'showAppOrFile': true
            },

            'file': {
                'title': 'notifications.malicious_file.malicious_file.title',
                'securityTitle': 'security.threats.dangerous.file.title',
                'noDeviceTitle': 'notifications.malicious_file.malicious_file.noDevice.title',
                'description': 'notifications.malicious_file.malicious_file.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.malware.file',
                'threat_desc': 'dashboard.threats.details.blocked.file',
                'showAppOrFile': true
            },

            'scanning': {
                'title': 'notifications.malicious_file.malicious_file.title',
                'securityTitle': 'security.threats.dangerous.file.title',
                'noDeviceTitle': 'notifications.malicious_file.malicious_file.noDevice.title',
                'description': 'notifications.malicious_file.malicious_file.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.malware.file',
                'threat_desc': 'dashboard.threats.details.blocked.file',
                'showAppOrFile': true
            },

            'process_memory_scan': {
                'title': 'notifications.malicious_process.malicious_process.title',
                'securityTitle': 'security.threats.dangerous.app.title',
                'noDeviceTitle': 'notifications.malicious_process.malicious_process.noDevice.title',
                'description': 'notifications.malicious_process.malicious_process.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.malicious.processes.app',
                'threat_desc': 'dashboard.threats.details.blocked.process',
                'showUrl': true
            }
        },
        'miner': {
            'default': {
                'title': this.threadsDeatilsKeys.notificationMaliciousUrl,
                'securityTitle': this.securityThreadsDeatilsKeys.notificationMaliciousUrl,
                'noDeviceTitle': this.threadsDeatilsKeys.notificationMaliciousUrlNoDevice,
                'description': 'dashboard.threats.details.malicious_url.miner.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.miner',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'box': {
                'default': {
                    'title': 'notifications.dangerous_url.dangerous_url.title.miner',
                    'securityTitle': 'security.threats.miner.page.blocked.title',
                    'noDeviceTitle': 'notifications.dangerous_url.dangerous_url.noDevice.title.miner',
                    'description': 'notifications.dangerous_url.dangerous_url.desc.miner',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': this.threadsDeatilsKeys.typeThreatMalware,
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                }
            },
            [NotificationsSubtypes.BMS]: {
                'title': this.threadsDeatilsKeys.maliciousHostTitle,
                'securityTitle': this.securityThreadsDeatilsKeys.maliciousHostTitle,
                'noDeviceTitle': this.threadsDeatilsKeys.maliciousHostNoDeviceTitle,
                'description': 'notifications.malicious_host.malicious_host.desc.miner',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'notifications.general.desc_threat.miner',
                'attack_type': this.threadsDeatilsKeys.typeThreatMalicious,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true,
                'showUrl': true
            }
        },
        'phishing': {
            'default': {
                'title': this.threadsDeatilsKeys.notificationMaliciousUrl,
                'securityTitle': this.securityThreadsDeatilsKeys.notificationMaliciousUrl,
                'noDeviceTitle': this.threadsDeatilsKeys.notificationMaliciousUrlNoDevice,
                'description': 'notifications.malicious_url.malicious_url.desc.phishing',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.phishing',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'box': {
                'default': {
                    'title': 'notifications.dangerous_url.dangerous_url.title.phishing',
                    'securityTitle': 'security.threats.phishing.page.blocked.title',
                    'noDeviceTitle': 'notifications.dangerous_url.dangerous_url.noDevice.title.phishing',
                    'description': 'notifications.dangerous_url.dangerous_url.desc.phishing',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': this.threadsDeatilsKeys.typeThreatMalware,
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                }
            },
            [NotificationsSubtypes.BMS]: {
                'title': this.threadsDeatilsKeys.maliciousHostTitle,
                'securityTitle': this.securityThreadsDeatilsKeys.maliciousHostTitle,
                'noDeviceTitle': this.threadsDeatilsKeys.maliciousHostNoDeviceTitle,
                'description': 'notifications.malicious_host.malicious_host.desc.phishing',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.phishing',
                'attack_type': this.threadsDeatilsKeys.typeThreatMalicious,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true,
                'showUrl': true
            }
        },
        'pua': {
            'default': {
                'title': this.threadsDeatilsKeys.notificatonDangerousUrl,
                'securityTitle': this.securityThreadsDeatilsKeys.notificatonDangerousUrl,
                'noDeviceTitle': this.threadsDeatilsKeys.notificatonDangerousUrlNoDevice,
                'description': 'dashboard.threats.details.malicious_url.pua.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatMalware,
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'box': {
                'default': {
                    'title': 'notifications.dangerous_url.dangerous_url.title.pua',
                    'securityTitle': 'security.threats.pua.page.blocked.title',
                    'noDeviceTitle': 'notifications.dangerous_url.dangerous_url.noDevice.title.pua',
                    'description': 'notifications.dangerous_url.dangerous_url.desc.pua',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': this.threadsDeatilsKeys.typeThreatMalware,
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                }
            },
            [NotificationsSubtypes.BMS]: {
                'title': this.threadsDeatilsKeys.maliciousHostTitle,
                'securityTitle': this.securityThreadsDeatilsKeys.maliciousHostTitle,
                'noDeviceTitle': this.threadsDeatilsKeys.maliciousHostNoDeviceTitle,
                'description': 'notifications.malicious_host.malicious_host.desc.pua',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'notifications.general.desc_threat.pua',
                'attack_type': this.threadsDeatilsKeys.typeThreatMalicious,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true,
                'showUrl': true
            }
        },
        'spam': {
            'box': {
                'default': {
                    'title': 'notifications.dangerous_url.dangerous_url.title.spam',
                    'securityTitle': 'security.threats.dangerous.page.title',
                    'noDeviceTitle': 'notifications.dangerous_url.dangerous_url.noDevice.title.spam',
                    'description': 'notifications.dangerous_url.dangerous_url.desc.spam',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.spam',
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                }
            }
        },
        'untrusted': {
            'default': {
                'title': this.threadsDeatilsKeys.notificationMaliciousUrl,
                'securityTitle': this.securityThreadsDeatilsKeys.notificationMaliciousUrl,
                'noDeviceTitle': this.threadsDeatilsKeys.notificationMaliciousUrlNoDevice,
                'description': 'notifications.malicious_url.malicious_url.desc.untrusted',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.untrusted',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'box': {
                'default': {
                    'title': 'notifications.dangerous_url.dangerous_url.title.untrusted',
                    'securityTitle': 'security.threats.untrasted.page.blocked.title',
                    'noDeviceTitle': 'notifications.dangerous_url.dangerous_url.noDevice.title.untrusted',
                    'description': 'notifications.dangerous_url.dangerous_url.desc.untrusted',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': this.threadsDeatilsKeys.typeThreatMalware,
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                }
            },
            [NotificationsSubtypes.BMS]: {
                'title': this.threadsDeatilsKeys.maliciousHostTitle,
                'securityTitle': this.securityThreadsDeatilsKeys.maliciousHostTitle,

                'noDeviceTitle': this.threadsDeatilsKeys.maliciousHostNoDeviceTitle,
                'description': 'notifications.malicious_host.malicious_host.desc.untrusted',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'notifications.general.desc_threat.untrusted',
                'attack_type': this.threadsDeatilsKeys.typeThreatMalicious,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true,
                'showUrl': true
            }
        },
        'ransomware': {
            'default': {
                'title': 'notifications.ransomware.ransomware.title',
                'securityTitle': 'security.threats.ransomware.blocked.title',
                'noDeviceTitle': 'notifications.ransomware.ransomware.noDevice.title',
                'description': 'notifications.ransomware.ransomware.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.card.ransomware',
                'threat_desc': this.threadsDeatilsKeys.bockedApp,
                'showAppOrFile': true
            }
        },
        'attack' : {
            // BOX
            'box': {
                'exploit': {
                    'title': 'notifications.network_attack.exploit_attack.title',
                    'securityTitle': 'security.threats.box.exploit.attack.title',
                    'noDeviceTitle': 'notifications.network_attack.exploit_attack.noDevice.title',
                    'description': 'notifications.network_attack.exploit_attack.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.box.attack.exploit',
                    'threat_desc': this.threadsDeatilsKeys.attackSource,
                    'showIp': true,
                    'showUrl': true
                },
                'brute': {
                    'title': 'notifications.network_attack.brute_force_attack.title',
                    'securityTitle': 'security.network_attack.brute_force_attack.title',
                    'noDeviceTitle': 'notifications.network_attack.brute_force_attack.noDevice.title',
                    'description': 'notifications.network_attack.brute_force_attack.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.box.attack.bruteforce',
                    'threat_desc': this.threadsDeatilsKeys.attackSource,
                    'threat_desc_port': 'dashboard.threats.details.port',
                    'threat_desc_protocol': 'dashboard.threats.details.protocol',
                    // attack source, port, protocol
                    'showIp': true,
                    'showUrl': true,
                    'showPort': true,
                    'showProtocol': true
                },
                'scanning': {
                    'title': 'notifications.network_attack.scanning_attack.title',
                    'securityTitle': 'security.network_attack.scanning_attack.title',
                    'noDeviceTitle': 'notifications.network_attack.scanning_attack.noDevice.title',
                    'description': 'notifications.network_attack.scanning_attack.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.box.attack.scanning',
                    'threat_desc': this.threadsDeatilsKeys.attackSource,
                    'showIp': true,
                    'showUrl': true,
                    'showAppOrFile': true
                },
                'lfi': {
                    'title': 'notifications.network_attack.lfi_attack.title',
                    'securityTitle': 'security.network_attack.lfi_attack.title',
                    'noDeviceTitle': 'notifications.network_attack.lfi_attack.noDevice.title',
                    'description': 'notifications.network_attack.lfi_attack.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.box.attack.lfi',
                    'threat_desc': this.threadsDeatilsKeys.externalIp,
                    'threat_desc_port': this.threadsDeatilsKeys.localPort,
                    // external ip si port
                    'showIp': true,
                    'showPort': true
                },
                'auth_bypass': {
                    'title': 'notifications.network_attack.auth_bypass.title',
                    'securityTitle': 'security.network_attack.auth_bypass.title',
                    'noDeviceTitle': 'notifications.network_attack.auth_bypass.noDevice.title',
                    'description': 'notifications.network_attack.auth_bypass.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.box.attack.auth_bypass',
                    'threat_desc': this.threadsDeatilsKeys.externalIp,
                    'threat_desc_port': this.threadsDeatilsKeys.localPort,
                    // external ip, local port
                    'showIp': true,
                    'showPort': true
                },
                'dos': {
                    // true sau false e in functie de lan_initiated
                    'true': {
                        'title': 'notifications.network_attack.dos_outgoing.title',
                        'securityTitle': 'security.threats.box.dos.title',
                        'noDeviceTitle': 'notifications.network_attack.dos_outgoing.noDevice.title',
                        'description': 'notifications.network_attack.dos_outgoing.desc',
                        'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                        'threat_type': 'dashboard.threats.details.type.threat.box.attack.dos.outgoing',
                        'threat_desc': 'dashboard.threats.details.targeted.ip',
                        'showIp': true
                    },
                    'false': {
                        'title': 'notifications.network_attack.dos_incoming.title',
                        'securityTitle': 'security.threats.box.dos.title',
                        'noDeviceTitle': 'notifications.network_attack.dos_incoming.noDevice.title',
                        'description': 'notifications.network_attack.dos_incoming.desc',
                        'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                        'threat_type': 'dashboard.threats.details.type.threat.box.attack.dos.incoming',
                        'threat_desc': this.threadsDeatilsKeys.externalIp,
                        'showIp': true
                    }
                }
            },
            // CL
            'bruteforce': {
                'title': 'notifications.cl_attack.cl_bruteforce_attack.title',
                'securityTitle': 'security.cl_attack.cl_bruteforce_attack.title',
                'noDeviceTitle': 'notifications.cl_attack.cl_bruteforce_attack.noDevice.title',
                'description': 'notifications.cl_attack.cl_bruteforce_attack.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.attack.bruteforce',
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true
            },
            'bruteforce_http': {
                'title': 'notifications.cl_attack.cl_bruteforce_attack.title',
                'securityTitle': 'security.cl_attack.cl_bruteforce_attack.title',
                'noDeviceTitle': 'notifications.cl_attack.cl_bruteforce_attack.noDevice.title',
                'description': 'notifications.cl_attack.cl_bruteforce_attack.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.attack.bruteforce',
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true
            },
            'lfi': {
                'title': 'notifications.cl_attack.cl_lfi_attack.title',
                'securityTitle': 'security.cl_attack.cl_lfi_attack.title',
                'noDeviceTitle': 'notifications.cl_attack.cl_lfi_attack.noDevice.title',
                'description': 'notifications.cl_attack.cl_lfi_attack.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatExploit,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true
            }
        },
        'privacy': {
            // BOX
            'box': {
                'banking': {
                    'title': 'notifications.privacy_threat.banking_privacy.title',
                    'securityTitle': 'security.privacy_threat.banking_privacy.title',
                    'noDeviceTitle': 'notifications.privacy_threat.banking_privacy.noDevice.title',
                    'description': 'dashboard.threats.details.privacy.banking.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.privacy.banking',
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                },
                'auth': {
                    'title': 'notifications.privacy_threat.auth_privacy.title',
                    'securityTitle': 'security.privacy_threat.auth_privacy.title',
                    'noDeviceTitle': 'notifications.privacy_threat.auth_privacy.noDevice.title',
                    'description': 'notifications.privacy_threat.auth_privacy.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': this.threadsDeatilsKeys.typeThreatPrivacy,
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                }
            },
            // CL
            'password': {
                'title': 'notifications.cl_privacy.cl_password_privacy.title',
                'securityTitle': 'security.cl_privacy.cl_password_privacy.title',
                'noDeviceTitle': 'notifications.cl_privacy.cl_password_privacy.noDevice.title',
                'description': 'notifications.cl_privacy.cl_password_privacy.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatPrivacy,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showUrl': true,
                'showIp': true
            },
            'banking': {
                'title': 'notifications.cl_privacy.cl_banking_privacy.title',
                'securityTitle': 'security.cl_privacy.cl_banking_privacy.title',
                'noDeviceTitle': 'notifications.cl_privacy.cl_banking_privacy.noDevice.title',
                'description': 'notifications.cl_privacy.cl_banking_privacy.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatPrivacy,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showUrl': true,
                'showIp': true
            },
            'data': {
                'title': 'notifications.cl_privacy.cl_data_privacy.title',
                'securityTitle': 'security.cl_privacy.cl_data_privacy.title',
                'noDeviceTitle': 'notifications.cl_privacy.cl_data_privacy.noDevice.title',
                'description': 'notifications.cl_privacy.cl_data_privacy.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatPrivacy,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showUrl': true,
                'showIp': true
            },
            'login': {
                'title': 'notifications.cl_privacy.cl_login_privacy.title',
                'securityTitle': 'security.cl_privacy.cl_login_privacy.title',
                'noDeviceTitle': 'notifications.cl_privacy.cl_login_privacy.noDevice.title',
                'description': 'notifications.cl_privacy.cl_login_privacy.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatPrivacy,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showUrl': true,
                'showIp': true
            }
        },
        'bot': {
            // BOX
            'box': {
                'default': {
                    'title': 'notifications.botnet.botnet.title',
                    'securityTitle': 'security.botnet.botnet.title',
                    'noDeviceTitle': 'notifications.botnet.botnet.noDevice.title',
                    'description': 'notifications.botnet.botnet.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.bot',
                    'threat_desc': this.threadsDeatilsKeys.blockedIp,
                    'showIp': true
                }
            },
            // CL
            'generic': {
                'title': 'notifications.cl_bot.cl_generic_bot.title',
                'securityTitle': 'security.cl_bot.cl_generic_bot.title',
                'noDeviceTitle': 'notifications.cl_bot.cl_generic_bot.noDevice.title',
                'description': 'notifications.cl_bot.cl_generic_bot.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.bot',
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true
            }
        },
        // BOX
        'anomaly': {
            'box': {
                'default': {
                    'title': 'notifications.certificate.certificate_expired.title',
                    'securityTitle': 'security.threats.box.prevented.connection.title',
                    'noDeviceTitle': 'notifications.certificate.certificate_expired.noDevice.title',
                    'description': 'notifications.network_anomaly.network_anomaly.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.anomaly',
                    'threat_desc': this.threadsDeatilsKeys.blockedIp,
                    'showIp': true
                }
            }
        },
        'certificate': {
            // BOX
            'box': {
                'certificate_expired': {
                    'title': 'notifications.certificate.certificate_expired.title',
                    'securityTitle': 'security.threats.box.prevented.connection.title',
                    'noDeviceTitle': 'notifications.certificate.certificate_expired.noDevice.title',
                    'description': 'notifications.certificate.certificate_expired.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.certificate.expired',
                    'threat_desc': this.threadsDeatilsKeys.blockedIp,
                    'showIp': true
                },
                'certificate_wrong_host': {
                    'title': 'notifications.certificate.certificate_wrong_host.title',
                    'securityTitle': 'security.certificate.certificate_wrong_host.title',
                    'noDeviceTitle': 'notifications.certificate.certificate_wrong_host.noDevice.title',
                    'description': 'notifications.certificate.certificate_wrong_host.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.certificate.wrong',
                    'threat_desc': this.threadsDeatilsKeys.blockedIp,
                    'showIp': true
                },
                'unsafe': {
                    'title': 'notifications.certificate.unsafe.title',
                    'securityTitle': 'security.threats.box.prevented.connection.title',
                    'noDeviceTitle': 'notifications.certificate.unsafe.noDevice.title',
                    'description': 'notifications.certificate.unsafe.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.certificate.unsafe',
                    'threat_desc': this.threadsDeatilsKeys.blockedIp,
                    'showIp': true
                },
                'cipher_suite_insecure': {
                    'title': 'notifications.certificate.cipher_suite_insecure.title',
                    'securityTitle': 'security.threats.box.prevented.connection.title',
                    'noDeviceTitle': 'notifications.certificate.cipher_suite_insecure.noDevice.title',
                    'description': 'notifications.certificate.cipher_suite_insecure.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.certificate.insecure',
                    'threat_desc': this.threadsDeatilsKeys.blockedIp,
                    'showIp': true
                },
                'key_exchange_insecure': {
                    'title': 'notifications.certificate.key_exchange_insecure.title',
                    'securityTitle': 'security.threats.box.prevented.connection.title',
                    'noDeviceTitle': 'notifications.certificate.key_exchange_insecure.noDevice.title',
                    'description': 'notifications.certificate.key_exchange_insecure.desc',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': 'dashboard.threats.details.type.threat.certificate.insecure',
                    'threat_desc': this.threadsDeatilsKeys.blockedIp,
                    'showIp': true
                }
            },
            // CL
            'untrusted_root': {
                'title': 'dashboard.threats.details.certificate.untrusted_root.title',
                'securityTitle': 'security.threats.suspicios.connection.title',
                'noDeviceTitle': 'dashboard.threats.details.certificate.untrusted_root.noDevice.title',
                'description': 'dashboard.threats.details.certificate.untrusted_root.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.certificate.untrusted',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'certificate_revoked': {
                'title': 'dashboard.threats.details.certificate.certificate_revoked.title',
                'securityTitle': 'security.threats.suspicios.connection.title',
                'noDeviceTitle': 'dashboard.threats.details.certificate.certificate_revoked.noDevice.title',
                'description': 'dashboard.threats.details.certificate.certificate_revoked.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.certificate.revoked',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'certificate_error': {
                'title': 'dashboard.threats.details.certificate.error.title',
                'securityTitle': 'security.threats.suspicios.connection.title',
                'noDeviceTitle': 'dashboard.threats.details.certificate.error.noDevice.title',
                'description': 'dashboard.threats.details.certificate.error.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.certificate.error',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'certificate_expired': {
                'title': 'dashboard.threats.details.certificate.expired.title',
                'securityTitle': 'security.threats.suspicios.connection.title',
                'noDeviceTitle': 'dashboard.threats.details.certificate.expired.noDevice.title',
                'description': 'dashboard.threats.details.certificate.expired.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.certificate.expired',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'certificate_self_signed': {
                'title': 'dashboard.threats.details.certificate.self-signed.title',
                'securityTitle': 'security.threats.suspicios.connection.title',
                'noDeviceTitle': 'dashboard.threats.details.certificate.self-signed.noDevice.title',
                'description': 'dashboard.threats.details.certificate.self-signed.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.certificate.self-sign',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            },
            'certificate_wrong_host': {
                'title': 'dashboard.threats.details.certificate.wrong_host.title',
                'securityTitle': 'security.threats.suspicios.connection.title',
                'noDeviceTitle': 'dashboard.threats.details.certificate.wrong_host.noDevice.title',
                'description': 'dashboard.threats.details.certificate.wrong_host.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.certificate.wrong-host',
                'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                'showUrl': true
            }
        },
        'exploit': {
            // BOX
            'box': {
                'default': {
                    'title': 'notifications.dangerous_url.dangerous_url.title.exploit',
                    'securityTitle': 'security.threats.untrasted.page.blocked.title',
                    'noDeviceTitle': 'notifications.dangerous_url.dangerous_url.noDevice.title.exploit',
                    'description': 'notifications.dangerous_url.dangerous_url.desc.exploit',
                    'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                    'threat_type': this.threadsDeatilsKeys.typeThreatExploit,
                    'threat_desc': this.threadsDeatilsKeys.blockedUrl,
                    'showUrl': true
                }
            },
            // CL
            'default': {
                'title': 'notifications.exploit.exploit.title',
                'securityTitle': 'security.threats.exploit.blocked.title',
                'noDeviceTitle': 'notifications.exploit.exploit.noDevice.title',
                'description': 'notifications.exploit.exploit.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatExploit,
                'threat_desc': this.threadsDeatilsKeys.bockedApp,
                'showAppOrFile': true
            },
            'login': {
                'title': 'notifications.cl_exploit.cl_login_exploit.title',
                'securityTitle': 'security.threats.exploit.command.injection.title',
                'noDeviceTitle': 'notifications.cl_exploit.cl_login_exploit.noDevice.title',
                'description': 'notifications.cl_exploit.cl_login_exploit.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatExploit,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true,
                'showUrl': true
            },
            'critical': {
                'title': 'notifications.cl_exploit.cl_critical_exploit.title',
                'securityTitle': 'security.threats.exploit.blocked.title',
                'noDeviceTitle': 'notifications.cl_exploit.cl_critical_exploit.noDevice.title',
                'description': 'notifications.cl_exploit.cl_critical_exploit.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatExploit,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showAppOrFile': true
            },
            'system': {
                'title': 'notifications.cl_exploit.cl_system_exploit.title',
                'securityTitle': 'security.threats.exploit.blocked.title',
                'noDeviceTitle': 'notifications.cl_exploit.cl_system_exploit.noDevice.title',
                'description': 'notifications.cl_exploit.cl_system_exploit.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatExploit,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showAppOrFile': true
            },
            'extension': {
                'title': 'notifications.cl_exploit.cl_extension_exploit.title',
                'securityTitle': 'security.threats.exploit.blocked.title',
                'noDeviceTitle': 'notifications.cl_exploit.cl_extension_exploit.noDevice.title',
                'description': 'notifications.cl_exploit.cl_extension_exploit.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatExploit,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showAppOrFile': true
            },
            'cmd': {
                'title': 'notifications.cl_exploit.cl_cmd_exploit.title',
                'securityTitle': 'security.threats.exploit.command.injection.title',
                'noDeviceTitle': 'notifications.cl_exploit.cl_cmd_exploit.noDevice.title',
                'description': 'notifications.cl_exploit.cl_cmd_exploit.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.exploit.command',
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true
            },
            'sql': {
                'title': 'notifications.cl_exploit.cl_sql_exploit.title',
                'securityTitle': 'security.cl_exploit.cl_sql_exploit.title',
                'noDeviceTitle': 'notifications.cl_exploit.cl_sql_exploit.noDevice.title',
                'description': 'notifications.cl_exploit.cl_sql_exploit.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.exploit.sql',
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true,
                'showUrl': true,
                'showAppOrFile': true
            },
            'traversal': {
                'title': 'notifications.cl_exploit.cl_traversal_exploit.title',
                'securityTitle': 'security.threats.exploit.blocked.title',
                'noDeviceTitle': 'notifications.cl_exploit.cl_traversal_exploit.noDevice.title',
                'description': 'notifications.cl_exploit.cl_traversal_exploit.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatExploit,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true,
                'showUrl': true
            },
            'generic': {
                'title': 'notifications.cl_exploit.cl_generic_exploit.title',
                'securityTitle': 'security.threats.exploit.blocked.title',
                'noDeviceTitle': 'notifications.cl_exploit.cl_generic_exploit.noDevice.title',
                'description': 'notifications.cl_exploit.cl_generic_exploit.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': this.threadsDeatilsKeys.typeThreatExploit,
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true
            }
        },
        // CL
        'trojan': {
            'generic': {
                'title': 'notifications.cl_trojan.cl_generic_trojan.title',
                'securityTitle': 'security.cl_trojan.cl_generic_trojan.title',
                'noDeviceTitle': 'notifications.cl_trojan.cl_generic_trojan.noDevice.title',
                'description': 'notifications.cl_trojan.cl_generic_trojan.desc',
                'confirmation': this.threadsDeatilsKeys.confirmationStatus,
                'threat_type': 'dashboard.threats.details.type.threat.trojan.generic',
                'threat_desc': this.threadsDeatilsKeys.attackSource,
                'showIp': true
            }
        }
    };

    omnitureEvents = {
        passwordManager: {
            install            : 'install',
            appStore           : 'downloadAppStore',
            googlePlay         : 'downloadGooglePlay',
            iosCentralApp      : 'downloadIosCentralApp',
            androidCentralApp  : 'downloadAndroidCentralApp'
        },
        recommendationCards: {
            install: 'ProtectionCardInstall',
            installVpn: 'ProtectionCardInstallVPN',
            buySlots: 'ProtectionCardBuyMoreSlots',
            buy: 'ProtectionCardBuy',
            buyTS: 'ProtectionCardBuyTS',
            buyPS: 'ProtectionCardBuyPS',
            buyUS: 'ProtectionCardBuyUS',
            buyVpn: 'ProtectionCardBuyVPN'
        },
        threats: {
            viewThreats: 'viewThreats',
            sevenDays: '7days',
            thirtyDays: '30days'
        }
    };

    recommendationCardsEvents = {
        [RecommendationCardTypes.PROTECTION]: {
            s0: 'protectioncard1:s0',
            s1: 'protectioncard1:s1',
            s2: 'protectioncard1:s2',
            s3: 'protectioncard1:s3',
            s4: 'protectioncard1:s4',
            s5: 'protectioncard1:s5'
        },
        [RecommendationCardTypes.UPGRADE]: {
            s6: 'protectioncard2:s6',
            s7: 'protectioncard2:s7'
        },
        [RecommendationCardTypes.VPN]: {
            s8: 'protectioncard3:s8',
            s9: 'protectioncard3:s9'
        }
    };

    cardSize = {
        sm: 'size-sm',
        md: 'size-md',
        lg: 'size-lg'
    };

    card = {
        devices: 'devices',
        threats: 'threats',
        leaks: 'leaks',
        breaches: 'breaches',
        lottery: 'lottery',
        community: 'community',
        passwordManagerSfr: 'passwordManagerSfr',
        passwordManager: 'passwordManager',
        recommendation: 'recommendation'
    };

    recommendationIcons = {
        downloadProtection: {type: 'general', icon: 'download'},//'download',
        localProtection: {type: 'feature', icon: 'local-protection'},//'local-protection',
        completeProtection: {type: 'feedback', icon: 'shield-check'},//'complete-protection',
        vpnProtection: {type: 'feature', icon: 'product-VPN'},//'shield-vpn',
        phoneProtection: {type: 'feature', icon: 'bms-promo'},//'phone-shielded'
    };

    recommendationTitles = {
        protectionTrialMultiPlatform: 'recommendation.protection.trial.multiplatform.title',
        protectionTrialSinglePlatform: 'recommendation.protection.trial.singlePlatform.title',
        buyProtection: 'recommendation.protection.end.buy.protection.title',
        recommendProtection: 'recommendation.protection.end.recommendation.multidevice.title',
        installDesktopProtection: 'recommendation.protection.end.install.desktop.protection.title',
        installMobileProtection: 'recommendation.protection.end.install.mobile.protection.title',
        protectAllDevices: 'recommendation.protection.end.protect.devices.title',
        upgradeTrial: 'recommendation.upgrade.trial.upgrade.title',
        upgradeTotalSecurity: 'recommendation.upgrade.total.security.title',
        upgradePremiumSecurity: 'recommendation.upgrade.premium.security.title',
        buyVpnPremium: 'recommendation.vpn.buy.premium.title',
        installMobileVpn: 'recommendation.vpn.mobile.installation.title'
    };

    recommendationDescriptions = {
        protectionTrialDaysDesc: 'recommendation.protection.trial.days.body',
        protectionTrialTodayDesc: 'recommendation.protection.trial.today.body',
        buyMoreProtectionDesc: 'recommendation.protection.end.buy.protection.body',
        startTrialDesc: 'recommendation.protection.end.recommendation.multidevice.body',
        protectDesktopDevicesDesc: 'recommendation.protection.end.install.desktop.protection.body',
        protectMobileDevicesDesc: 'recommendation.protection.end.install.mobile.protection.body',
        protectAllDevicesDesc: 'recommendation.protection.end.protect.devices.body',
        upgradeTrialDaysDesc: 'recommendation.upgrade.trial.days.upgrade.body',
        upgradeTrialTodayDesc: 'recommendation.upgrade.trial.today.upgrade.body'
    };

    recommendationButtons = {
        installOnOtherDevicesBtn: 'recommendation.protection.trial.button',
        buyMoreProtectionBtn: 'recommendation.protection.end.buy.protection.button',
        installNowBtn: 'recommendation.protection.end.recommendation.install.button',
        upgradeTrialBtn: 'recommendation.upgrade.trial.upgrade.button',
        upgradeTotalSecurityBtn: 'recommendation.upgrade.total.security.button',
        upgradePremiumSecurityBtn: 'recommendation.upgrade.premium.security.button',
        upgradeUltimateSecurityBtn: 'recommendation.upgrade.ultimate.security.button',
        buyVpnPremiumBtn: 'recommendation.vpn.buy.premium.button',
        installMobileVpnBtn: 'recommendation.vpn.mobile.installation.button'
    };

    recommendationButtonActions = {
        installNow: 'recommendation.installNow',
        installOnOtherDevices: 'recommendation.installOnOtherDevices',
        buyMoreProtection: 'recommendation.buyMoreProtection',
        buyUpgrade: 'upgrade.buy',
        upgradeToTs: 'upgrade.upgradeToTS',
        upgradeToTSNew: 'upgrade.upgradeToTSNew',
        upgradeToPS: 'upgrade.upgradeToPS',
        upgradeToPSNew: 'upgrade.upgradeToPSNew',
        upgradeToUSNew: 'upgrade.upgradeToUSNew',
        buyPremiumVpn: 'vpn.buyPremiumVpn',
        installMobileVpn: 'vpn.installMobileVpn'
    };

    recommendationScenarios = {
        'protection': {
            'trial': {
                'multiPlatformForDays': {
                    icon: this.recommendationIcons.downloadProtection,
                    title: this.recommendationTitles.protectionTrialMultiPlatform,
                    body: this.recommendationDescriptions.protectionTrialDaysDesc,
                    button: this.recommendationButtons.installOnOtherDevicesBtn,
                    buttonAction: this.recommendationButtonActions.installOnOtherDevices
                },
                'multiPlatformForToday': {
                    icon: this.recommendationIcons.downloadProtection,
                    title: this.recommendationTitles.protectionTrialMultiPlatform,
                    body: this.recommendationDescriptions.protectionTrialTodayDesc,
                    button: this.recommendationButtons.installOnOtherDevicesBtn,
                    buttonAction: this.recommendationButtonActions.installOnOtherDevices
                },
                'singlePlatformForDays': {
                    icon: this.recommendationIcons.downloadProtection,
                    title: this.recommendationTitles.protectionTrialSinglePlatform,
                    body: this.recommendationDescriptions.protectionTrialDaysDesc,
                    button: this.recommendationButtons.installOnOtherDevicesBtn,
                    buttonAction: this.recommendationButtonActions.installOnOtherDevices
                },
                'singlePlatformForToday': {
                    icon: this.recommendationIcons.downloadProtection,
                    title: this.recommendationTitles.protectionTrialSinglePlatform,
                    body: this.recommendationDescriptions.protectionTrialTodayDesc,
                    button: this.recommendationButtons.installOnOtherDevicesBtn,
                    buttonAction: this.recommendationButtonActions.installOnOtherDevices
                }
            },
            'end': {
                'buyProtection': {
                    icon: this.recommendationIcons.localProtection,
                    title: this.recommendationTitles.buyProtection,
                    body: this.recommendationDescriptions.buyMoreProtectionDesc,
                    button: this.recommendationButtons.buyMoreProtectionBtn,
                    buttonAction: this.recommendationButtonActions.buyMoreProtection
                },
                'startTrial': {
                    icon: this.recommendationIcons.downloadProtection,
                    title: this.recommendationTitles.recommendProtection,
                    body: this.recommendationDescriptions.startTrialDesc,
                    button: this.recommendationButtons.installNowBtn,
                    buttonAction: this.recommendationButtonActions.installNow
                },
                'protectDesktopDevices': {
                    icon: this.recommendationIcons.downloadProtection,
                    title: this.recommendationTitles.installDesktopProtection,
                    body: this.recommendationDescriptions.protectDesktopDevicesDesc,
                    button: this.recommendationButtons.installNowBtn,
                    buttonAction: this.recommendationButtonActions.installNow
                },
                'protectMobileDevices': {
                    icon: this.recommendationIcons.downloadProtection,
                    title: this.recommendationTitles.installMobileProtection,
                    body: this.recommendationDescriptions.protectMobileDevicesDesc,
                    button: this.recommendationButtons.installNowBtn,
                    buttonAction: this.recommendationButtonActions.installNow
                },
                'protectAllDevices': {
                    icon: this.recommendationIcons.downloadProtection,
                    title: this.recommendationTitles.protectAllDevices,
                    body: this.recommendationDescriptions.protectAllDevicesDesc,
                    button: this.recommendationButtons.installNowBtn,
                    buttonAction: this.recommendationButtonActions.installNow
                }
            }
        },
        'upgrade': {
            'trial': {
                'trialForDays': {
                    icon: this.recommendationIcons.completeProtection,
                    title: this.recommendationTitles.upgradeTrial,
                    body: this.recommendationDescriptions.upgradeTrialDaysDesc,
                    button: this.recommendationButtons.upgradeTrialBtn,
                    buttonAction: this.recommendationButtonActions.buyUpgrade
                },
                'trialForToday': {
                    icon: this.recommendationIcons.completeProtection,
                    title: this.recommendationTitles.upgradeTrial,
                    body: this.recommendationDescriptions.upgradeTrialTodayDesc,
                    button: this.recommendationButtons.upgradeTrialBtn,
                    buttonAction: this.recommendationButtonActions.buyUpgrade
                }
            },
            'end': {
                'totalSecurity': {
                    icon: this.recommendationIcons.completeProtection,
                    title: this.recommendationTitles.upgradeTotalSecurity,
                    button: this.recommendationButtons.upgradeTotalSecurityBtn,
                    buttonAction: this.recommendationButtonActions.upgradeToTs
                },
                'totalSecurityNew': {
                    icon: this.recommendationIcons.completeProtection,
                    title: this.recommendationTitles.upgradeTotalSecurity,
                    button: this.recommendationButtons.upgradeTotalSecurityBtn,
                    buttonAction: this.recommendationButtonActions.upgradeToTSNew
                },
                'premiumSecurity': {
                    icon: this.recommendationIcons.completeProtection,
                    title: this.recommendationTitles.upgradePremiumSecurity,
                    button: this.recommendationButtons.upgradePremiumSecurityBtn,
                    buttonAction: this.recommendationButtonActions.upgradeToPS
                },
                'premiumSecurityNew': {
                    icon: this.recommendationIcons.completeProtection,
                    title: this.recommendationTitles.upgradePremiumSecurity,
                    button: this.recommendationButtons.upgradePremiumSecurityBtn,
                    buttonAction: this.recommendationButtonActions.upgradeToPSNew
                },
                'ultimateSecurityNew': {
                    icon: this.recommendationIcons.completeProtection,
                    title: this.recommendationTitles.upgradePremiumSecurity,
                    button: this.recommendationButtons.upgradeUltimateSecurityBtn,
                    buttonAction: this.recommendationButtonActions.upgradeToUSNew
                }
            }
        },
        'vpn': {
            'end': {
                'buyPremiumVpn': {
                    icon: this.recommendationIcons.vpnProtection,
                    title: this.recommendationTitles.buyVpnPremium,
                    button: this.recommendationButtons.buyVpnPremiumBtn,
                    buttonAction: this.recommendationButtonActions.buyPremiumVpn
                },
                'installMobileVpn': {
                    icon: this.recommendationIcons.phoneProtection,
                    title: this.recommendationTitles.installMobileVpn,
                    button: this.recommendationButtons.installMobileVpnBtn,
                    buttonAction: this.recommendationButtonActions.installMobileVpn
                }
            }
        }
    };

}
