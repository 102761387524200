import { ThreatTypes } from "../Threats.model";

export enum WebmailProtectionInboxProviders {
    GMAIL = 'gmail',
    OUTLOOK = 'outlook'
}

export enum WebmailProtectionInboxActions {
    REGISTERED = 'registered',
    PAUSED = 'paused',
    REMOVED = 'removed',
    RESUMED = 'resumed'
}

export enum WebmailProtectionScanDescriptionStatus {
    ACTIVE = 'active',
    PAUSED = 'paused'
}

export enum WebmailProtectionButtonTypes {
    PAUSE_ACTIVITY = 'pauseActivity',
    RESUME_ACTIVITY = 'resumeActivity',
    REMOVE_EMAIL = 'removeEmail'
}

export enum WebmailProtectionKeySpecificTypes {
    CURRENTLY_USED = 'currentlyUsed',
    PREVIOUSLY_USED = 'previouslyUsed',
    NEXT_IN_QUEUE = 'nextInQueue',
}

export enum WebmailProtectionKeyAlgorithm {
    RSA_OAEP = 'RSA-OAEP',
    RSA_OAEP_256 = 'RSA-OAEP-256'
}

export enum WebmailProtectionKeyFormat {
    JWK = 'jwk'
}

export enum WebmailProtectionKeyHash {
    SHA_256 = 'SHA-256'
}

export enum WebmailProtectionKeyUsage {
    ENCRYPT = 'encrypt',
    DECRYPT = 'decrypt'
}

export interface WebmailProtectionKey {
    kty: string,
    n: string,
    e: string,
    use: string,
    alg: string,
    kid: string,
    type: WebmailProtectionKeySpecificTypes
}

export interface WebmailProtectionRegisterInboxParameters {
    email: string;
    inbox_provider: string;
    access_token: WebmailProtecitonAuthToken;
    refresh_token: WebmailProtecitonAuthToken;
    kid: string;
    user_token: string;
}

export interface WebmailProtectionOauth2Tokens {
    access_token: string;
    refresh_token: string;
    scope?: string;
}

export interface WebmailProtectionOauth2EncryptedTokens {
    access_token: WebmailProtecitonAuthToken;
    refresh_token: WebmailProtecitonAuthToken;
    scope?: string;
}

type WebmailProtecitonAuthToken = string|string[]

export enum WebmailProtectionOauth2Errors {
    ACCESS_DENIED = 'access_denied',
    INVALID_GRANT = 'invalid_grant'
}

export interface IWebmailProtectionInboxes {
    [key: string]: WebmailProtectionInbox
}

export interface IWebmailProtectionSummary {
    [key: string]: WebmailProtectionSummary
}

export interface IWebmailProtectionGroupedActivities {
    [key: number]: WebmailProtectionGroupedActivity[]
}

export interface WebmailProtectionGroupedActivity {
    action: string;
    actionDescription: WebmailProtectionActionDescription;
    email: string;
    inbox_provider: string;
    providerImg: WebmailProtectionProvider;
    timestamp: number;
}

export interface WebmailProtectionInbox {
    email: string;
    inbox_provider: string;
    service_id: string;
    scan_active: boolean;
    created: number;
    updated: number;
    last_scan: number;
    providerImg?: {
        link: string;
        alt?: string;
    };
    scanDescription?: WebmailProtectionScanDescription;
    buttonActions?: Array<WebmailProtectionButton>;
    protectionStatus?: WebmailProtectionInboxSummary;
    hasError?: boolean;
    isLoading?: boolean;
    loadingDescription?: string;
}

export type WebmailProtectionLoadingDescription = {
    [key in WebmailProtectionButtonTypes]: string;
}

export type IWebmailProtectionInboxesActivity = WebmailProtectionInboxesActivity[];

export class WebmailProtectionInboxesActivity {
    timestamp: number;
    activities: WebmailProtectionGroupedActivity[];
}

export class WebmailProtectionInboxActivity {
    email: string;
    inbox_provider: string;
    action: string;
    timestamp: number;
}

export class WebmailProtectionSummaryModel {
    timestamp?: number;
}

export type WebmailProtectionScanDescriptions = {
    [key in WebmailProtectionScanDescriptionStatus]: WebmailProtectionScanDescription;
};

export interface WebmailProtectionSummary {
    timestamp?: number;
    clean?: number;
    [ThreatTypes.DANGEROUS]?: number;
    [ThreatTypes.MALWARE]?: number;
    [ThreatTypes.PHISHING]?: number;
    [ThreatTypes.FRAUD]?: number;
    [ThreatTypes.PUA]?: number;
    [ThreatTypes.RANSOMWARE]?: number;
    [ThreatTypes.CERTIFICATE]?: number;
    [ThreatTypes.UNTRUSTED]?: number;
    [ThreatTypes.EXPLOIT]?: number;
    [ThreatTypes.ATTACK]?: number;
    [ThreatTypes.PRIVACY]?: number;
    [ThreatTypes.ANOMALY]?: number;
    [ThreatTypes.BOT]?: number;
    [ThreatTypes.SPAM]?: number;
    [ThreatTypes.MINER]?: number;
    [ThreatTypes.TROJAN]?: number;
    [ThreatTypes.MALICIOUS_HOST]?: number;
}

export interface WebmailProtectionInboxSummary {
    email?: string;
    clean?: number;
    dangerous?: number;
}

export interface WebmailProtectionButton {
    type: WebmailProtectionButtonTypes;
    buttonName: string;
    buttonAction: string;
    icon: string;
    iconType: string,
    color: string;
}

export type WebmailProtectionButtons = {
    [key in WebmailProtectionButtonTypes]: WebmailProtectionButton;
};

export interface WebmailProtectionChartDataset {
    datasets: Array<number|WebmailProtectionTooltip>;
    labels: Array<number|WebmailProtectionTooltip>;
    tooltips: Array<number|WebmailProtectionTooltip>;
}

export interface WebmailProtectionTooltip {
    summary: Array<WebmailProtectionSummaryTooltip>;
    summaryValues: {
        safe: number;
        dangerous: number;
    };
    threats: Array<WebmailProtectionThreat>;
}

export interface WebmailProtectionSummaryTooltip {
    text: string;
    hasThreats: boolean;
}

export interface WebmailProtectionThreat {
    text: string;
    placeholder: WebmailProtectionSummary;
}

export interface WebmailProtectionScanDescription {
    text: string;
    icon: string;
    iconType: string,
    color: string;
}

export interface WebmailProtectionActionDescription {
    text: string;
    icon: string;
    iconType: string;
    color: string;
}

export type WebmailProtectionOauth2VerifyTokenResponse = {
    issued_to?: string;
    key?: string;
};

export class WebmailProtectionProvider {
    link?: string;
    alt?: string;
}

export type WebmailProtectionOauth2GmailTokenInfoSuccessResponse = {
    email: string,
    [key: string]: string
};

export type WebmailProtectionOauth2GmailTokenInfoErrorResponse = {
    error?: string,
    error_description?: string,
    [key: string]: string
};

export type WebmailProtectionOauth2OutlookTokenInfoSuccessResponse = {
    mail: string,
    [key: string]: any
};

export type WebmailProtectionOauth2OutlookTokenInfoErrorResponse = {
    error: {
        code: string,
        message: string
    },
    [key: string]: any
};

export class WebmailProtectionSlots {
    total: number;
    active: number;
};

export interface WebmailProtectionOauth2TokenInfoErrorFalgs {
    notEnoughPermissions: boolean;
    defaultError: boolean;
    emailAlreadyRegistered: boolean;
    maximumNumberOfEmailsReached: boolean;
    wrongCredentials: boolean;
}
