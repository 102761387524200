// External
import { Injectable } from '@angular/core';
import { OperatingSystems, ProductsToInstall, ValuesService } from '../values/values.service';
import { ConfigService } from './config.service';
import { ValuesSupportService } from '../../pages/support/values/values-support.service';
import { LanguageService } from '../services/core/language.service';
import { ProfilesService } from '../services/process/profiles/profiles.service';
import { AppsConfigService } from './apps.config.service';
import { UsefulService } from '../services/global/useful/useful.service';
import { AppLevels } from '../values/subscriptions.values.service';
import { ALL_PLATFORMS } from '../services/interfaces/isubscriptions/isubscriptions.service';
import {
    AppByLevelOutlineType,
    AppByLevelProperties,
    App, BundleConfigurationInterface,
    BundleConfigurationObject,
    BundleConfigurationTypes
} from './ProductsConfig.model';

@Injectable({
    providedIn: 'root'
})

export class ProductsConfigService {
    constructor(
        private readonly configService: ConfigService,
        private readonly valuesService: ValuesService,
        private readonly valuesSupportService: ValuesSupportService,
        private readonly languageService: LanguageService,
        private readonly profilesService: ProfilesService,
        private readonly usefulService: UsefulService,
        private readonly appsConfigService: AppsConfigService
    ) {}

    /* PRODUCTS - custom names for each language */
    private readonly productsConfig = {
        [this.valuesService.productNameAV]: {
            /**
             * Example
             * this.valuesService.languages.fr_FR.id: 'custom text',
             *
            */

            default: this.valuesService.productNameAV
        },
        [this.valuesService.productNameIS]: {
            default: this.valuesService.productNameIS
        },
        [this.valuesService.productNameTS]: {
            default: this.valuesService.productNameTS
        },
        [this.valuesService.productNameBMS]: {
            default: this.valuesService.productNameBMS
        },
        [this.valuesService.productNameBIS]: {
            default: this.valuesService.productNameBIS
        },
        [this.valuesService.productNameAVFM]: {
            default: this.valuesService.productNameAVFM
        },
        [this.valuesService.productNamePA]: {
            default: this.valuesService.productNamePA
        },
        [this.valuesService.productNamePANCC]: {
            default: this.valuesService.productNamePANCC
        },
        [this.valuesService.productNameTSMD]: {
            default: this.valuesService.productNameTSMD
        },
        [this.valuesService.productNameFP]: {
            default: this.valuesService.productNameFP
        },
        [this.valuesService.productNameFPAV]: {
            default: this.valuesService.productNameFPAV
        },
        [this.valuesService.productNameFPIS]: {
            default: this.valuesService.productNameFPIS
        },
        [this.valuesService.productNameISMD]: {
            default: this.valuesService.productNameISMD
        },
        [this.valuesService.productNameBoxCl]: {
            default: this.valuesService.productNameBoxCl
        },
        [this.valuesService.productNameBox]: {
            default: this.valuesService.productNameBox
        },
        [this.valuesService.productNameBOX2]: {
            default: this.valuesService.productNameBOX2
        },
        [this.valuesService.productNamePAS]: {
            default: this.valuesService.productNamePAS
        },
        [this.valuesService.productNameAVFREE]: {
            default: this.valuesService.productNameAVFREE
        },
        [this.valuesService.productNameAVLITE]: {
            default: this.valuesService.productNameAVLITE
        },
        [this.valuesService.productNameVPNBasic]: {
            default: this.valuesService.productNameVPNBasic
        },
        [this.valuesService.productNameVPNPremium]: {
            default: this.valuesService.productNameVPNPremium
        },
        [this.valuesService.productNameDIP]: {
            default: this.valuesService.productNameDIP
        },
        [this.valuesService.productNameWallet]: {
            default: this.valuesService.productNameWallet
        },
        [this.valuesService.productNamePasswordManager]: {
            default: this.valuesService.productNamePasswordManager
        },
        [this.valuesService.productNameIDTP]: {
            default: this.valuesService.productNameIDTP
        },
        [this.valuesService.productNameIDTPP]: {
            default: this.valuesService.productNameIDTPP
        },
        [this.valuesService.productNameIDTPS]: {
            default: this.valuesService.productNameIDTPS
        },
        [this.valuesService.productNameUSUS]: {
            default: this.valuesService.productNameUSUS
        },
        [this.valuesService.productNameUSEU]: {
            default: this.valuesService.productNameUSEU
        },
        [this.valuesService.productNameUSPUS]: {
            default: this.valuesService.productNameUSPUS
        },
        [this.valuesService.productNamePS]: {
            default: this.valuesService.productNamePS
        },
        [this.valuesService.productNamePSPlus]: {
            default: this.valuesService.productNamePSPlus
        },
        [this.valuesService.productNameSecurity]: {
            default: this.valuesService.productNameSecurity
        },
        [this.valuesService.productNameBoxDefault]: {
            default: this.valuesService.productNameBoxDefault
        },
        [this.valuesService.productNameWebmailProtection]: {
            default: this.valuesService.productNameWebmailProtection
        },
        [this.valuesService.productNameProtection]: {
            default: this.valuesService.productNameProtection
        }
    };

    /* Manufacturer name - custom name for each language */
    private readonly manufacturerNameConfig = {
        /**
         * Example
         * this.valuesService.languages.fr_FR.id: 'custom text',
        */
        default: this.configService.config.manufacturerName
    };

    /* Bundles */
    private readonly bundlesConfig: BundleConfigurationInterface = {
        [this.valuesService.bundleAVFM]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/avformac.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/antivirus-for-mac.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/antivirus-for-mac.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/antivirus-for-mac.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/antivirus-for-mac/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/antivirus-for-mac/EN.pdf'
                },
                name: {
                    /**
                     * Example
                     * this.valuesService.languages.fr_FR.id: 'custom text',
                     *
                    */

                    default: this.valuesService.productNameAVFM
                },
                desc: 'subscriptions.description.avformac',
                mainDesc: 'subscriptions.maindescription.avformac.new'
            }
        },
        [this.valuesService.bundleAVFREE]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/antivirusfree.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/free.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/free.html',
                systemRequirements: 'https://www.bitdefender.com/solutions/free.html',
                userGuide: {
                    [this.valuesService.languages.nl_NL.id]: 'https://download.bitdefender.com/resources/media/materials/2021/userguides/nl_NL/bd_avfree_userguide__nl.pdf',
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/antivirus-free/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/antivirus-free/EN.pdf'
                },
                name: {
                    default: `${this.valuesService.productNameAVFREE} Edition`
                },
                desc: 'subscriptions.description.avfree',
                mainDesc: 'subscriptions.maindescription.avfree.new'
            }
        },
        [this.valuesService.bundleAVLITE]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/default-free.png'),
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNameAVLITE
                },
                desc: 'subscriptions.description.avlite',
                mainDesc: 'subscriptions.maindescription.avlite.new'
            }
        },
        [this.valuesService.bundleBMS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/bms.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/mobile-security-android.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/mobile-security-android.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/mobile-security-android.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/mobile-security-android/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/mobile-security-android/EN.pdf'
                },
                name: {
                    default: `${this.valuesService.productNameBMS} for Android`
                },
                desc: 'subscriptions.description.bms',
                mainDesc: 'subscriptions.maindescription.bms.new'
            }
        },
        [this.valuesService.bundleBOXSE]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/box.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNameBox
                },
                desc: 'subscriptions.description.box1',
                mainDesc: 'subscriptions.maindescription.box1'
            }
        },
        [this.valuesService.bundleBoxCl]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/box.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNameBox
                },
                desc: 'subscriptions.description.box_tsmd',
                mainDesc: 'subscriptions.maindescription.box_tsmd'
            }
        },
        [this.valuesService.bundleBOX2]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/boxv2.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: 'https://download.bitdefender.com/resources/media/materials/box/v2/user_guide/2020/BOX_UserGuide_v2_en.pdf'
                },
                name: {
                    default: this.valuesService.productNameBOX2
                },
                desc: 'subscriptions.description.boxse_tsmd',
                mainDesc: 'subscriptions.maindescription.boxse_tsmd'
            }
        },
        [this.valuesService.bundleAV]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/av.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/antivirus.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/antivirus.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/antivirus.html#system-requirements',
                userGuide: {
                    [this.valuesService.languages.nl_NL.id]: 'https://download.bitdefender.com/resources/media/materials/2021/userguides/nl_NL/bitdefender_av_userguide_nl.pdf',
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/antivirus-plus/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/antivirus-plus/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNameAV
                },
                desc: 'subscriptions.description.cl.av',
                mainDesc: 'subscriptions.maindescription.cl.av.new'
            }
        },
        [this.valuesService.bundleAVNEW]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/av.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/antivirus.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/antivirus.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/antivirus.html#system-requirements',
                userGuide: {
                    [this.valuesService.languages.nl_NL.id]: 'https://download.bitdefender.com/resources/media/materials/2021/userguides/nl_NL/bitdefender_av_userguide_nl.pdf',
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/antivirus-plus/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/antivirus-plus/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNameAV
                },
                desc: 'subscriptions.description.individual.cl.av.new',
                mainDesc: 'subscriptions.maindescription.individual.cl.av.new'
            }
        },
        [this.valuesService.bundleIS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/is.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/internet-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/internet-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/internet-security.html#system-requirements',
                userGuide: {
                    [this.valuesService.languages.nl_NL.id]: 'https://download.bitdefender.com/resources/media/materials/2021/userguides/nl_NL/bitdefender_is_userguide_nl.pdf',
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/internet-security/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/internet-security/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNameIS
                },
                desc: 'subscriptions.description.cl.is',
                mainDesc: 'subscriptions.maindescription.cl.is.new'
            }
        },
        [this.valuesService.bundleTS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/total-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/total-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/total-security/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/total-security/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNameTS
                },
                desc: 'subscriptions.description.cl.ts',
                mainDesc: 'subscriptions.maindescription.cl.ts'
            }
        },
        [this.valuesService.bundleFP]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/fp.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/family-pack.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/family-pack.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/family-pack.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/family-pack/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/family-pack/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNameFP
                },
                desc: 'subscriptions.description.fp',
                mainDesc: 'subscriptions.maindescription.fp.new'
            }
        },
        [this.valuesService.bundleFPP]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/fp.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: `${this.valuesService.productNameFP} with ${this.valuesService.productNamePA}`
                },
                desc: 'subscriptions.description.fp.parental',
                mainDesc: 'subscriptions.maindescription.fp.parental'
            }
        },
        [this.valuesService.bundleFPAV]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/fpav.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNameFPAV
                },
                desc: 'subscriptions.description.fp.av',
                mainDesc: 'subscriptions.maindescription.fp.av'
            }
        },
        [this.valuesService.bundleFPIS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/fpis.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNameFPIS
                },
                desc: 'subscriptions.description.fp.is',
                mainDesc: 'subscriptions.maindescription.fp.is'
            }
        },
        [this.valuesService.bundleIOSBMS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/iosbms.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                supportName: `${this.getManufacturerName()} ${this.valuesService.productNameBMS}`,
                name: {
                    default: `${this.getManufacturerName()} ${this.valuesService.productNameBMS} for Android & iOS`
                },
                desc: 'subscriptions.description.iosbms',
                mainDesc: 'subscriptions.maindescription.iosbms'
            }
        },
        [this.valuesService.bundleBIS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/iossecurity.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/mobile-security-ios.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/mobile-security-ios.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/mobile-security-ios.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/mobile-security-ios/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/mobile-security-ios/EN.pdf'
                },
                name: {
                    default: `${this.valuesService.productNameBIS} for iOS Free`
                },
                desc: 'subscriptions.description.iossecurity',
                mainDesc: 'subscriptions.maindescription.iossecurity.new'
            }
        },
        [this.valuesService.bundleBIP]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/iosprotection.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/mobile-security-ios.html'
                },
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                supportName: `${this.valuesService.productNameBIP}`,
                name: {
                    default: `${this.valuesService.productNameBIP} for iOS`
                },
                desc: 'subscriptions.description.iosprotection',
                mainDesc: 'subscriptions.maindescription.iosprotection.new'
            }
        },
        [this.valuesService.bundleISMD]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/ismd.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNameISMD
                },
                desc: 'subscriptions.description.cl.is.multidevice',
                mainDesc: 'subscriptions.maindescription.cl.is.multidevice'
            }
        },
        [this.valuesService.bundlePA]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/parentalcontrolsecurity.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNamePA
                },
                desc: 'subscriptions.description.parentaladvisor',
                mainDesc: 'subscriptions.maindescription.parentaladvisor'
            }
        },
        [this.valuesService.bundlePANCC]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/nccparental.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNamePANCC
                },
                desc: 'subscriptions.description.parentaladvisor',
                mainDesc: 'subscriptions.maindescription.parentaladvisor'
            }
        },
        [this.valuesService.bundlePSec]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/premiumsecurity.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/premium-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/premium-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/premium-security.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/premium-security/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/premium-security/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNamePS
                },
                desc: 'subscriptions.description.premiumsecurity',
                mainDesc: 'subscriptions.maindescription.premiumsecurity.new'
            }
        },
        [this.valuesService.bundlePSecNew]: {
            [BundleConfigurationTypes.INDIVIDUAL]: {
                mainDesc: 'subscriptions.maindescription.individual.premiumsecurity.new',
                desc: 'subscriptions.description.individual.premiumsecurity.new',
                name: {
                    default: `${this.valuesService.productNamePS} Individual Plan`
                }
            },
            [BundleConfigurationTypes.FAMILYPLAN]: {
                mainDesc: 'subscriptions.maindescription.family.plan.premiumsecurity.new',
                desc: 'subscriptions.description.family.plan.premiumsecurity.new',
                name: {
                    default: `${this.valuesService.productNamePS} Family Plan`
                }
            },
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/premiumsecurity.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/premium-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/premium-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/premium-security.html#system-requirements',
                userGuide: {
                    default: 'https://www.bitdefender.com/user-guide/ps'
                }
            }
        },
        [this.valuesService.bundlePSecPlus]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/premiumsecurityplus.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/premium-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/premium-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/premium-security.html#system-requirements',
                userGuide: {
                    [this.valuesService.languages.de_DE.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/premium-security-plus/DE.pdf',
                    [this.valuesService.languages.es_ES.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/premium-security-plus/ES.pdf',
                    [this.valuesService.languages.fr_CA.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/premium-security-plus/FR.pdf',
                    [this.valuesService.languages.it_IT.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/premium-security-plus/IT.pdf',
                    [this.valuesService.languages.nl_NL.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/premium-security-plus/NL.pdf',
                    default: ''
                },
                name: {
                    default: this.valuesService.productNamePSPlus
                },
                desc: 'subscriptions.description.premiumsecurityplus',
                mainDesc: 'subscriptions.maindescription.premiumsecurityplus.new'
            }
        },
        [this.valuesService.bundleUSEUNEW]: {
            [BundleConfigurationTypes.INDIVIDUAL]: {
                desc: 'subscriptions.description.individual.ultimatesecurityeu',
                mainDesc: 'subscriptions.maindescription.individual.ultimatesecurityeu',
                name: {
                    default: `${this.valuesService.productNameUSEU} Individual Plan`
                }
            },
            [BundleConfigurationTypes.FAMILYPLAN]: {
                desc: 'subscriptions.description.family.plan.ultimatesecurityeu',
                mainDesc: 'subscriptions.maindescription.family.plan.ultimatesecurityeu',
                name: {
                    default: `${this.valuesService.productNameUSEU} Family Plan`
                }
            },
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/premiumsecurityplus.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/ultimate-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/ultimate-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/ultimate-security.html#system-requirements',
                userGuide: {
                    default: 'https://www.bitdefender.com/user-guide/us'
                }
            }
        },
        [this.valuesService.bundleSecIdentity]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/digitalprotection.png'),
                seeAllFeatures: '',
                systemRequirements: 'https://www.bitdefender.com/solutions/password-manager.html',
                name: {
                    default: this.valuesService.productNameSecIdentity
                },
                desc: 'subscriptions.description.secureidentity',
                mainDesc: 'subscriptions.maindescription.secureidentity'
            }
        },
        [this.valuesService.bundlePAS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/parentalcontrolsecurity.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNamePAS
                },
                desc: 'subscriptions.description.parentalsecurity',
                mainDesc: 'subscriptions.maindescription.parentalsecurity'
            }
        },
        [this.valuesService.bundleSOHO]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/soho.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/small-office-security.html'
                },
                seeAllFeatures: '',
                systemRequirements: 'https://www.bitdefender.com/solutions/small-office-security.html#system-requirements',
                userGuide: {
                    default: 'https://download.bitdefender.com/resources/media/materials/2020/userguides/en_EN/bitdefender_soho_2020_userguide_en.pdf'
                },
                name: {
                    default: 'Small Office Security'
                },
                desc: 'subscriptions.description.soho',
                mainDesc: 'subscriptions.maindescription.soho.new'
            }
        },
        [this.valuesService.bundleTSMD]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'nl_NL': this.configService.config.pathImages.concat('subscriptions/bundles/tsmd.png'),
                    'de_DE': this.configService.config.pathImages.concat('subscriptions/bundles/tsmd.png'),
                    default: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png')
                },
                website: {
                    default: 'https://www.bitdefender.com/solutions/total-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/total-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/total-security/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/total-security/EN.pdf'
                },
                name: {
                    'nl_NL': this.valuesService.productNameTS,
                    'de_DE': `${this.valuesService.productNameTS} Multi-Device`,
                    default: `${this.valuesService.productNameTS} Multi-Device`
                },
                desc: 'subscriptions.description.tsmd',
                mainDesc: 'subscriptions.maindescription.tsmd.new'
            }
        },
        [this.valuesService.bundleTSMDNEW]: {
            [BundleConfigurationTypes.INDIVIDUAL]: {
                mainDesc: 'subscriptions.maindescription.individual.tsmd.new',
                desc: 'subscriptions.description.individual.tsmd.new',
                name: {
                    default: `${this.valuesService.productNameTS} Individual Plan`
                }
            },
            [BundleConfigurationTypes.FAMILYPLAN]: {
                mainDesc: 'subscriptions.maindescription.family.plan.tsmd.new',
                desc: 'subscriptions.description.family.plan.tsmd.new',
                name: {
                    default: `${this.valuesService.productNameTS} Family Plan`
                }
            },
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    default: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png')
                },
                website: {
                    default: 'https://www.bitdefender.com/solutions/total-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/total-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
                userGuide: {
                    default: 'https://www.bitdefender.com/user-guide/ts'
                }
            }
        },
        [this.valuesService.bundleCLTSMD]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'nl_NL': this.configService.config.pathImages.concat('subscriptions/bundles/tsmd.png'),
                    'de_DE': this.configService.config.pathImages.concat('subscriptions/bundles/tsmd.png'),
                    default: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png')
                },
                website: {
                    default: 'https://www.bitdefender.com/solutions/total-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/total-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/total-security/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/total-security/EN.pdf'
                },
                name: {
                    'nl_NL': this.valuesService.productNameTS,
                    'de_DE': `${this.valuesService.productNameTS} Multi-Device`,
                    default: `${this.valuesService.productNameTS} Multi-Device`
                },
                desc: 'subscriptions.description.tsmd',
                mainDesc: 'subscriptions.maindescription.tsmd'
            }
        },
        [this.valuesService.bundleVPN]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/vpn-standard.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/vpn.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/vpn.html',
                systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/vpn/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/vpn/EN.pdf'
                },
                name: {
                    basic: {
                        default: this.valuesService.productNameVPNBasic
                    },
                    premium: {
                        default: this.valuesService.productNameVPNPremium
                    }
                },
                desc: {
                    basic: 'subscriptions.description.vpnbasic',
                    premium: 'subscriptions.description.vpnpremium'
                },
                mainDesc: {
                    basic: 'subscriptions.maindescription.vpnbasic.new',
                    premium: 'subscriptions.maindescription.vpnpremium.new'
                }
            }
        },
        [this.valuesService.bundleVPNSOHO]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/vpn-standard.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    basic: {
                        default: `${this.valuesService.productNameVPNBasic} For Business`
                    },
                    premium: {
                        default: `${this.valuesService.productNameVPNPremium} For Business`
                    }
                },
                desc: {
                    basic: 'subscriptions.description.vpnsoho',
                    premium: 'subscriptions.description.vpnsoho'
                },
                mainDesc: {
                    basic: 'subscriptions.maindescription.vpnsoho',
                    premium: 'subscriptions.maindescription.vpnsoho'
                }
            }
        },
        [this.valuesService.bundleSHS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'en_US': this.configService.config.pathImages.concat('subscriptions/bundles/smart-home-service.png'),
                    'other': this.configService.config.pathImages.concat('subscriptions/bundles/smart-home-service.png')
                },
                website: {
                    default: 'https://www.bitdefender.com/premium-services/home-network-support.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/premium-services/home-network-support.html',
                systemRequirements: 'https://www.bitdefender.com/premium-services/home-network-support.html',
                userGuide: {
                    default: ''
                },
                name: {
                    'en_US': 'Smart Home Security',
                    default: 'Smart Home Security'
                },
                desc: 'subscriptions.description.smarthomesecurity',
                mainDesc: 'subscriptions.maindescription.smarthomesecurity'
            }
        },
        [this.valuesService.bundleCTU]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'en_US': this.configService.config.pathImages.concat('subscriptions/bundles/computer-tuneup.png'),
                    'other': this.configService.config.pathImages.concat('subscriptions/bundles/computer-tuneup.png')
                },
                website: {
                    default: 'https://www.bitdefender.com/premium-services/computer-tune-up.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/premium-services/computer-tune-up.html',
                systemRequirements: 'https://www.bitdefender.com/premium-services/computer-tune-up.html',
                userGuide: {
                    default: ''
                },
                name: {
                    'en_US': 'Computer Tune-Up',
                    'es_ES': 'Optimizar el Equipo',
                    'fr_FR': 'Nettoyage & Optimisation',
                    'de_DE': 'Computer-Tuning',
                    default: 'Computer Tune-Up'
                },
                desc: 'subscriptions.description.tuneup',
                mainDesc: 'subscriptions.maindescription.tuneup'
            }
        },
        [this.valuesService.bundleSTFPC]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'other': this.configService.config.pathImages.concat('subscriptions/bundles/multi-platform.png')
                },
                website: {
                    default: 'https://www.bitdefender.com/premium-services/install-setup-multiplatform.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/premium-services/install-setup-multiplatform.html',
                systemRequirements: 'https://www.bitdefender.com/premium-services/install-setup-multiplatform.html',
                userGuide: {
                    default: ''
                },
                name: {
                    default: 'Install and Setup'
                },
                desc: 'subscriptions.description.installandsetup',
                mainDesc: 'subscriptions.maindescription.installandsetup'
            }
        },
        [this.valuesService.bundleSTFM]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'other': this.configService.config.pathImages.concat('subscriptions/bundles/multi-platform.png')
                },
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: 'Install and Setup for Mac'
                },
                desc: '',
                mainDesc: ''
            }
        },
        [this.valuesService.bundlePCO]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'other': this.configService.config.pathImages.concat('subscriptions/bundles/pc-optimizer.png')
                },
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: 'PC Optimizer'
                },
                desc: '',
                mainDesc: ''
            }
        },
        [this.valuesService.bundleSYSR]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'other': this.configService.config.pathImages.concat('subscriptions/bundles/system-repair.png')
                },
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: 'System Repair'
                },
                desc: '',
                mainDesc: ''
            }
        },
        [this.valuesService.bundleVR]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'en_US': this.configService.config.pathImages.concat('subscriptions/bundles/virus-exterminator.png'),
                    'other': this.configService.config.pathImages.concat('subscriptions/bundles/virus-exterminator.png')
                },
                website: {
                    default: 'https://www.bitdefender.com/premium-services/virus-and-spyware-removal.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/premium-services/virus-and-spyware-removal.html',
                systemRequirements: 'https://www.bitdefender.com/premium-services/virus-and-spyware-removal.html',
                userGuide: {
                    default: ''
                },
                name: {
                    'en_US': 'Virus and Spyware Removal',
                    'fr_FR': 'Suppression de Virus et de Spywares',
                    'de_DE': 'Viren- und Sopyware-Entfernung',
                    default: 'Virus and Spyware Removal'
                },
                desc: 'subscriptions.description.virusandspyware',
                mainDesc: 'subscriptions.maindescription.virusandspyware'
            }
        },
        [this.valuesService.bundleVIPS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'other': this.configService.config.pathImages.concat('subscriptions/bundles/vip-support.png')
                },
                website: {
                    default: 'https://www.bitdefender.com/premium-services/vip-support.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/premium-services/vip-support.html',
                systemRequirements: 'https://www.bitdefender.com/premium-services/vip-support.html',
                userGuide: {
                    default: ''
                },
                name: {
                    default: 'VIP Support'
                },
                desc: 'subscriptions.description.vip',
                mainDesc: 'subscriptions.maindescription.vip'
            }
        },
        [this.valuesService.bundleSTMP]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: {
                    'other': this.configService.config.pathImages.concat('subscriptions/bundles/vip-support.png')
                },
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: 'Install & SetUp Multi-Platform'
                },
                desc: '',
                mainDesc: ''
            }
        },
        [this.valuesService.bundleHVA]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/hvaprotection.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/home-scanner.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/home-scanner.html',
                systemRequirements: 'https://www.bitdefender.com/solutions/home-scanner.html',
                userGuide: {
                    default: 'https://download.bitdefender.com/resources/media/materials/2018/userguides/en_EN/Bitdefender_Home_Scanner_en.pdf'
                },
                name: {
                    default: 'Home Scanner'
                },
                desc: 'subscriptions.description.hva',
                mainDesc: 'subscriptions.maindescription.hva'
            }
        },
        [this.valuesService.bundleBox]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: '',
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: this.valuesService.productNameBox
                },
                desc: 'subscriptions.description.box_tsmd',
                mainDesc: 'subscriptions.maindescription.box_tsmd'
            }
        },
        [this.valuesService.bundleTSVPN]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
                website: {
                    default: 'https://www.bitdefender.com/solutions/total-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/total-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/total-security.html#system-requirements',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/total-security/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/total-security/EN.pdf'
                },
                name: {
                    default: `${this.valuesService.productNameTS} & ${this.valuesService.productNameVPNBasic}`
                },
                desc: 'subscriptions.description.tsmdvpn',
                mainDesc: 'subscriptions.maindescription.tsmdvpn'
            }
        },
        [this.valuesService.bundlsSUMP]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: '',
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: 'Setup mp'
                },
                desc: '',
                mainDesc: ''
            }
        },
        [this.valuesService.bundlesSetup]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: '',
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                name: {
                    default: 'Setup'
                },
                desc: '',
                mainDesc: ''
            }
        },
        [this.valuesService.bundleDPI]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/digitalprotection.png'),
                website: {
                    [this.valuesService.languages.fr_FR.id]: 'https://www.bitdefender.fr/solutions/digital-identity-protection.html',
                    [this.valuesService.languages.fr_CA.id]: 'https://www.bitdefender.fr/solutions/digital-identity-protection.html',
                    [this.valuesService.languages.en_GB.id]: 'https://www.bitdefender.co.uk/solutions/digital-identity-protection.html',
                    [this.valuesService.languages.en_AU.id]: 'https://www.bitdefender.com.au/solutions/digital-identity-protection.html',
                    [this.valuesService.languages.de_DE.id]: 'https://www.bitdefender.de/solutions/digital-identity-protection.html',
                    default: 'https://www.bitdefender.com/solutions/digital-identity-protection.html'
                },
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    [this.valuesService.languages.en_US.id]: 'https://download.bitdefender.com/resources/media/materials/2021/userguides/en_EN/bitdefender_digital_identity_protection_userguide_en.pdf',
                    [this.valuesService.languages.de_DE.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/digital-identity-protection/DE.pdf',
                    [this.valuesService.languages.es_ES.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/digital-identity-protection/ES.pdf',
                    [this.valuesService.languages.fr_FR.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/digital-identity-protection/FR.pdf',
                    [this.valuesService.languages.fr_CA.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/digital-identity-protection/FR.pdf',
                    [this.valuesService.languages.it_IT.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/digital-identity-protection/IT.pdf',
                    [this.valuesService.languages.nl_NL.id]: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/digital-identity-protection/NL.pdf',
                    default: 'https://download.bitdefender.com/resources/media/materials/2021/userguides/en_EN/bitdefender_digital_identity_protection_userguide_en.pdf'
                },
                name: {
                    default: this.valuesService.productNameDIP
                },
                desc: 'subscriptions.description.dataprivacy.new',
                mainDesc: 'subscriptions.maindescription.dataprivacy.new'
            }
        },
        [this.valuesService.bundleStartPlan]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/start_plan.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                supportName: 'Start Plan',
                name: {
                    default: 'Antivirus'
                },
                plan: 'Start Plan',
                desc: 'subscriptions.description.startplan',
                mainDesc: 'subscriptions.maindescription.startplan.new'
            }
        },
        [this.valuesService.bundlePersonalPlan]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                supportName: 'Personal Plan',
                name: {
                    default: this.valuesService.productNameTS
                },
                plan: 'Personal Plan',
                desc: 'subscriptions.description.personalplan',
                mainDesc: 'subscriptions.maindescription.personalplan.new'
            }
        },
        [this.valuesService.bundleFamilyPlan]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                supportName: 'Family Plan',
                name: {
                    default: this.valuesService.productNameTS
                },
                plan: 'Family Plan',
                desc: 'subscriptions.description.familyplan',
                mainDesc: 'subscriptions.maindescription.familyplan.new'
            }
        },
        [this.valuesService.bundleUltimatePlan]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    default: ''
                },
                supportName: 'Ultimate Plan',
                name: {
                    default: this.valuesService.productNameTS
                },
                plan: 'Ultimate Plan',
                desc: 'subscriptions.description.ultimateplan',
                mainDesc: 'subscriptions.maindescription.ultimateplan.new'
            }
        },
        [this.valuesService.bundlePM]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.valuesService.centralImages.passwordManagerBundle.link,
                website: {
                    default: 'https://www.bitdefender.com/solutions/password-manager.html'
                },
                seeAllFeatures: '',
                systemRequirements: '',
                userGuide: {
                    localize: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/password-manager/%s.pdf',
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/password-manager/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNamePasswordManager
                },
                desc: 'subscriptions.description.passmanager',
                mainDesc: 'subscriptions.maindescription.passmanager.new'
            }
        },
        [this.valuesService.bundleIDTPS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.valuesService.centralImages.idTheft.link,
                website: {
                    default: 'https://www.bitdefender.com/solutions/identity-theft-protection.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/identity-theft-protection.html',
                userGuide: {
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/identity-theft-protection/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNameIDTPS
                },
                desc: 'subscriptions.description.idtps',
                mainDesc: 'subscriptions.maindescription.idtps.new'
            }
        },
        [this.valuesService.bundleIDTPP]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.valuesService.centralImages.idTheft.link,
                website: {
                    default: 'https://www.bitdefender.com/solutions/identity-theft-protection.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/identity-theft-protection.html',
                userGuide: {
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/identity-theft-protection/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNameIDTPP
                },
                desc: 'subscriptions.description.idtpp',
                mainDesc: 'subscriptions.maindescription.idtpp.new'
            }
        },
        [this.valuesService.bundleUSUS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.valuesService.centralImages.ultimateSecurity.link,
                website: {
                    default: 'https://www.bitdefender.com/solutions/ultimate-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/ultimate-security.html',
                systemRequirements:'https://www.bitdefender.com/solutions/ultimate-security.html#system-requirements',
                userGuide: {
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/ultimate-security/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNameUSUS
                },
                desc: 'subscriptions.description.usus',
                mainDesc: 'subscriptions.maindescription.usus.new'
            }
        },
        [this.valuesService.bundleUSUSNEW]: {
            [BundleConfigurationTypes.INDIVIDUAL]: {
                mainDesc: 'subscriptions.maindescription.individual.ultimatesecurityus.new',
                desc: 'subscriptions.description.individual.ultimatesecurityus.new',
                name: {
                    default: `${this.valuesService.productNameUSUS} Individual Plan. Standard Version.`
                }
            },
            [BundleConfigurationTypes.FAMILYPLAN]: {
                mainDesc: 'subscriptions.maindescription.family.plan.ultimatesecurityus.new',
                desc: 'subscriptions.description.family.plan.ultimatesecurityus.new',
                name: {
                    default: `${this.valuesService.productNameUSUS} Family Plan. Standard Version.`
                }
            },
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.valuesService.centralImages.ultimateSecurity.link,
                website: {
                    default: 'https://www.bitdefender.com/solutions/ultimate-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/ultimate-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/ultimate-security.html#system-requirements',
                userGuide: {
                    default: 'https://www.bitdefender.com/user-guide/usp-s'
                }
            }
        },
        [this.valuesService.bundleUSPUS]: {
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.valuesService.centralImages.ultimateSecurity.link,
                website: {
                    default: 'https://www.bitdefender.com/solutions/ultimate-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/ultimate-security.html',
                systemRequirements: 'https://www.bitdefender.com/solutions/ultimate-security.html#system-requirements',
                userGuide: {
                    default: 'https://www.bitdefender.com/content/dam/bitdefender/consumers/case-studies/ultimate-security/EN.pdf'
                },
                name: {
                    default: this.valuesService.productNameUSPUS
                },
                desc: 'subscriptions.description.uspus',
                mainDesc: 'subscriptions.maindescription.uspus.new'
            }
        },
        [this.valuesService.bundleUSPUSNEW]: {
            [BundleConfigurationTypes.INDIVIDUAL]: {
                mainDesc: 'subscriptions.maindescription.individual.ultimatesecurityus.new',
                desc: 'subscriptions.description.individual.ultimatesecurityus.new',
                name: {
                    default: `${this.valuesService.productNameUSPUS} Individual Plan. Extended Version.`
                }
            },
            [BundleConfigurationTypes.FAMILYPLAN]: {
                mainDesc: 'subscriptions.maindescription.family.plan.ultimatesecurityus.new',
                desc: 'subscriptions.description.family.plan.ultimatesecurityus.new',
                name: {
                    default: `${this.valuesService.productNameUSPUS} Family Plan. Extended Version.`
                }
            },
            [BundleConfigurationTypes.DEFAULT]: {
                pic: this.valuesService.centralImages.ultimateSecurity.link,
                website: {
                    default: 'https://www.bitdefender.com/solutions/ultimate-security.html'
                },
                seeAllFeatures: 'https://www.bitdefender.com/solutions/ultimate-security.html#features',
                systemRequirements: 'https://www.bitdefender.com/solutions/ultimate-security.html#system-requirements',
                userGuide: {
                    default: 'https://www.bitdefender.com/user-guide/usp-e'
                }
            }
        }
    };

    /* Apps */
    private readonly appByLevelInfo: AppByLevelOutlineType = {
        [ALL_PLATFORMS]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameTS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppNameTotalSecurityMultiPlatform()
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameTS,
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameTS
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appCL.ts.description',
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.PROTECTION
            }
        },
        [this.valuesService.appAVFM]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: 'Antivirus',
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appAVFM)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    /**
                     * Example
                     * this.valuesService.languages.fr_FR.id: 'custom text',
                     *
                    */
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAVFM,
                    [this.valuesService.languages.ja_JP.id]: 'for Mac'
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAVFM,
                    [this.valuesService.languages.ja_JP.id]: 'for Mac'
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appAVFM.description',
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.PROTECTION
            }
        },
        [this.valuesService.appCL]: {
            [AppLevels.AV]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameAV,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appCL)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAV
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAV
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appCL.av.description'
            },
            [AppLevels.IS]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameIS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appCL)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIS
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIS
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appCL.is.description'
            },
            [AppLevels.TS]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameTS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appCL)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameTS,
                    [this.valuesService.languages.ja_JP.id]: ' '
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameTS,
                    [this.valuesService.languages.ja_JP.id]: ' '
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appCL.ts.description'
            },
            [AppLevels.EPP]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: 'Antivirus'
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Antivirus'
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png')
            },
            [AppLevels.EPP_PRIVACY]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameTS
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameTS
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png')
            },
            [AppLevels.LITE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameAVFREE,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAVFREE
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-free.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appAVFREE.description',
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.PROTECTION
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: 'Antivirus'
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Antivirus'
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png'),
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.PROTECTION
            }
        },
        [this.valuesService.appAVFREE]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAVFREE
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameAVFREE
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-free.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appAVFREE.description',
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.PROTECTION
            }
        },
        [this.valuesService.appOptimizeDvm]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: ''
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: ''
                },
                [AppByLevelProperties.IMAGE]: ''
            }
        },
        [this.valuesService.appBMS]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameBMS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appBMS)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBMS} for Android`,
                    [this.valuesService.languages.ja_JP.id]: 'for Android'
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBMS} for Android`,
                    [this.valuesService.languages.ja_JP.id]: 'for Android'
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appBMS.description',
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.PROTECTION
            }
        },
        [this.valuesService.appIOSBMS]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBMS} for Android and iOS`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBMS} for Android and iOS`
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png')
            }
        },
        [this.valuesService.appBIP]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameBIP,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appBIP)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBIP} for iOS`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBIP} for iOS`
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appBIP.description',
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.PROTECTION
            }
        },
        [this.valuesService.appBIS]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameBIS,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appBIS)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBIS} for iOS Free`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameBIS} for iOS Free`
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/default-free.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appBIS.description',
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.PROTECTION
            }
        },
        [this.valuesService.appPA]: {
            [AppLevels.BASIC]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNamePA,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appPA)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePA
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePA
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/parentalcontrolpremium.png')
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNamePA,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appPA)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePA
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePA
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/parentalcontrolpremium.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appPA.description'
            }
        },
        [this.valuesService.appPANCC]: {
            [AppLevels.PREMIUM]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNamePANCC,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appPANCC)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePANCC
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePANCC
                },
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appPANCC.description'
            },
            [AppLevels.BASIC]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNamePANCC,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appPANCC)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePANCC
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePANCC
                }
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePANCC
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePANCC
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/nccparental.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appPANCC.description'
            }
        },
        [this.valuesService.appBOX1]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: 'Basic Network Security'
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Basic Network Security'
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/box.png')
            }
        },
        [this.valuesService.appBOX2]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: 'Advanced Network Security'
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Advanced Network Security'
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/boxv2.png')
            }
        },
        [this.valuesService.appVPN]: {
            [AppLevels.BASIC]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameVPNBasic,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appVPN)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameVPNBasic} Standard`
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: `${this.valuesService.productNameVPNBasic} Standard`
                }
            },
            [AppLevels.PREMIUM]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameVPNPremium,
                        [AppByLevelProperties.LOWER]: () => this.computeInstallModalPartialAppName(this.valuesService.appVPN)
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: 'VPN Premium'
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'VPN Premium'
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/vpn-premium.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appVPN.premium.description'
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameVPNBasic
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameVPNBasic
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/vpn-standard.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appVPN.description',
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.VPN
            }
        },
        [this.valuesService.appDIP]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameDIP,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameDIP
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameDIP
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/digitalprotection.png'),
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appDIP.description'
            }
        },
        [this.valuesService.appHVA]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: 'Home Scanner'
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: 'Home Scanner'
                },
                [AppByLevelProperties.IMAGE]: this.configService.config.pathImages.concat('subscriptions/bundles/hvaprotection.png')
            }
        },
        [this.valuesService.appPassManager]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNamePasswordManager,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePasswordManager
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNamePasswordManager
                },
                [AppByLevelProperties.IMAGE]: this.valuesService.centralImages.passwordManagerBundle.link,
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appPassManager.description',
                [AppByLevelProperties.PRODUCT]: ProductsToInstall.PASSWORDMANAGER
            }
        },
        [this.valuesService.appIdTheft]: {
            [AppLevels.STANDARD]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameIDTPS,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIDTPS
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIDTPS
                }
            },
            [AppLevels.PREMIUM]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameIDTPP,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIDTPP
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIDTPP
                },
                [AppByLevelProperties.IMAGE]: this.valuesService.centralImages.idTheftPremium.link,
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appIdTheft.premium.description'
            },
            [AppLevels.NONE]: {
                [AppByLevelProperties.INSTALL_MODAL_NAME]: {
                    [AppByLevelProperties.DEFAULT]: {
                        [AppByLevelProperties.UPPER]: this.valuesService.productNameIDTP,
                        [AppByLevelProperties.LOWER]: () => ''
                    }
                },
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIDTP
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameIDTP
                },
                [AppByLevelProperties.IMAGE]: this.valuesService.centralImages.idTheft.link,
                [AppByLevelProperties.DESCRIPTION]: 'subscriptions.appIdTheft.description'
            }
        },
        [this.valuesService.appWebmailProtection]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameWebmailProtection
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameWebmailProtection
                }
            }
        },
        [this.valuesService.appProtection]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameProtection
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameProtection
                }
            }
        },
        [this.valuesService.appSecurity]: {
            [AppLevels.NONE]: {
                [AppByLevelProperties.NAME]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameSecurity
                },
                [AppByLevelProperties.NAME_V3]: {
                    [AppByLevelProperties.DEFAULT]: this.valuesService.productNameSecurity
                }
            }
        }
    };

    /* Products Info */
    private readonly supportProductsConfig = [
        {},
        {
            bundle_id: this.valuesSupportService.allProducts.box2,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.box2)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.box2],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.box2)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.totalSecurity,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.totalSecurity)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.totalSecurity],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.totalSecurity)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.internetSecurity,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.internetSecurity)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.internetSecurity],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.internetSecurity)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.antivirusFree,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.antivirusFree)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.antivirusFree],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.antivirusFree)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.antivirusPlus,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.antivirusPlus)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.antivirusPlus],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.antivirusPlus)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.avForMac,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.avForMac)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.avForMac],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.avForMac)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.familyPack,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.familyPack)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.familyPack],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.familyPack)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.premiumSecurity,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.premiumSecurity)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.premiumSecurity],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.premiumSecurity)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.soho,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.soho)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.soho],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.soho)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.bms,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.security,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.bms),
                operatingSystem: this.valuesService.compatibleOsInfo[OperatingSystems.ANDROID].display
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.bms],
            subject: this.getManufacturerNameEnglish().concat(' ', this.getBundleNameEnglish(this.valuesSupportService.allProducts.bms),
                    ' for ', this.valuesService.compatibleOsInfo[OperatingSystems.ANDROID].display)
        },
        {
            bundle_id: this.valuesSupportService.allProducts.iosProtection,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.security,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.iosProtection),
                operatingSystem: this.valuesService.compatibleOsInfo[OperatingSystems.IOS].display
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.iosProtection],
            subject: this.getManufacturerNameEnglish().concat(' ', this.getBundleSupportName(this.valuesSupportService.allProducts.iosProtection),
                    ' for ', this.valuesService.compatibleOsInfo[OperatingSystems.IOS].display)
        },
        {
            bundle_id: this.valuesSupportService.allProducts.vpn,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.vpn, this.valuesService.subscriptionsLevels.basic)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.vpn],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.vpn, this.valuesService.subscriptionsLevels.basic)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.passManager,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleName(this.valuesSupportService.allProducts.passManager)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.passManager],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleNameEnglish(this.valuesSupportService.allProducts.passManager)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.dataPrivacy,
            label: {
                product: () => this.getBundleName(this.valuesSupportService.allProducts.dataPrivacy)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.dataPrivacy],
            subject: this.getBundleNameEnglish(this.valuesSupportService.allProducts.dataPrivacy)
        },
        {
            bundle_id: this.valuesSupportService.allProducts.startPlan,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.startPlan)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.startPlan],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleSupportName(this.valuesSupportService.allProducts.startPlan)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.familyPlan,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.familyPlan)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.familyPlan],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleSupportName(this.valuesSupportService.allProducts.familyPlan)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.ultimatePlan,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.ultimatePlan)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.ultimatePlan],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleSupportName(this.valuesSupportService.allProducts.ultimatePlan)}`
        },
        {
            bundle_id: this.valuesSupportService.allProducts.personalPlan,
            label: {
                text: this.valuesSupportService.supportFormProductsTextsKeys.general,
                product: () => this.getBundleSupportName(this.valuesSupportService.allProducts.personalPlan)
            },
            value: this.valuesSupportService.productMapping[this.valuesSupportService.allProducts.personalPlan],
            subject: `${this.getManufacturerNameEnglish()} ${this.getBundleSupportName(this.valuesSupportService.allProducts.personalPlan)}`
        },
        {
            bundle_id: '',
            label: {
                text: 'support.form.opt.product.other'
            },
            value: this.valuesSupportService.productMapping.other,
            subject: this.valuesSupportService.defaultFormSubject
        }
    ];

    /* Apps Info */
    private readonly devicesAppsConfig = {
        [this.valuesService.appAVFM] : {
            appId : this.valuesService.appAVFM,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.protection.osx',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appAVFREE] : {
            appId : this.valuesService.appAVFREE,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.free.protection.windows',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appCL] : {
            appId : this.valuesService.appCL,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.protection.windows',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appBMS] : {
            appId : this.valuesService.appBMS,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.protection.android',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appBIP] : {
            appId : this.valuesService.appBIP,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.protection.ios',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appBIS] : {
            appId : this.valuesService.appBIS,
            icon: this.valuesService.appsIcons.PROTECTION,
            name: '',
            nameFunction: () => 'devices.installed.apps.free.protection.ios',
            translate: true,
            genericName: 'devices.details.apps.protection'
        },
        [this.valuesService.appPA] : {
            appId : this.valuesService.appPA,
            icon: this.valuesService.appsIcons.PARENTAL,
            name: '',
            nameFunction: () => this.getProductName(this.valuesService.productNamePA),
            translate: false,
            genericName: 'devices.details.apps.others'
        },
        [this.valuesService.appPANCC] : {
            appId : this.valuesService.appPANCC,
            icon: this.valuesService.appsIcons.PARENTALNCC,
            name: '',
            nameFunction: () => this.getProductName(this.valuesService.productNamePANCC),
            translate: false,
            genericName: 'devices.details.apps.others'
        },
        [this.valuesService.appVPN] : {
            appId : this.valuesService.appVPN,
            icon: this.valuesService.appsIcons.VPN,
            name: '',
            nameFunction: () => this.getProductName(this.valuesService.productNameVPNBasic),
            translate: false,
            genericName: 'devices.details.apps.others'
        }
    };

    /**
     * Computes the subtitle for an app in the new install modal
     * @private
     * @memberof ProductsConfigService
     * @param {string} appId The app id
     * @returns {string} The subtitle
     */
    private computeInstallModalPartialAppName(appId: string): string {
        const displayOses = this.computeDisplayedOses(appId);
        return 'for '.concat(displayOses.join(', '));
    }

    /**
     * Computes the subtitle for the total security multi platform app in the new install modal
     * @private
     * @memberof ProductsConfigService
     * @returns {string} The subtitle for the multi platform total security app in the new install modal
     */
    private computeInstallModalPartialAppNameTotalSecurityMultiPlatform(): string {
        let availableOses = [];
        const installModalTotalSecurityApps = this.appsConfigService.getInstallModalTotalSecurityApps();
        const displayOses = [];

        for (const appId of installModalTotalSecurityApps) {
            availableOses = availableOses.concat(this.appsConfigService.getAvailableOses(appId));
        }

        for (const os of availableOses) {
            if (os === OperatingSystems.OSX) {
                continue;
            }
            displayOses.push(this.usefulService.getDeviceOSForDisplay(os));
        }
        return 'for '.concat(displayOses.join(', '));
    }

    /**
     * Computes available oses for a given app
     * @public
     * @memberof ProductsConfigService
     * @param {string} appId The app id
     * @returns {Array} Array of available oses
     */
    public computeDisplayedOses(appId: string): Array<string> {
        const availableOses = this.appsConfigService.getAvailableOses(appId);
        const displayOses = [];
        for (const os of availableOses) {
            if (os === OperatingSystems.OSX) {
                continue;
            }
            const osForDisplay = this.usefulService.getDeviceOSForDisplay(os);
            if (osForDisplay) {
                displayOses.push(osForDisplay);
            }
        }
        return displayOses;
    }

    /**
     * Gets the products for the support form
     * @public
     * @memberof ProductsConfigService
     * @returns {object[]} The products for the support form
     */
    public getSupportProducts(): object[] {
        return this.supportProductsConfig;
    }

    /**
     * Gets the bundle id for a certain product from support form
     * @public
     * @memberof ProductsConfigService
     * @param {number} index The index of the product
     * @returns {string} The bundle id
     */
    public getSupportProductBundleId(index: number): string {
        return this.supportProductsConfig[index]?.bundle_id;
    }

    /**
     * Gets the display name for a certain product from support form
     * @public
     * @memberof ProductsConfigService
     * @param {number} index The index of the product
     * @returns {string} The display name
     */
    public getSupportProductDisplayName(index: number): string {
        return this.supportProductsConfig[index]?.value;
    }

    /**
     * Gets the ticket subject for a certain product from support form
     * @public
     * @memberof ProductsConfigService
     * @param {number} index The index of the product
     * @returns {string} The ticket subject
     */
    public getSupportProductSubject(index: number): string {
        const partialSubject = this.supportProductsConfig[index]?.subject ?? this.valuesSupportService.defaultFormSubject;
        const fromCentralStrong = 'From Central: ';
        return fromCentralStrong.concat(partialSubject);
    }

    /**
     * Get box product name
     * @public
     * @memberof ProductsConfigService
     * @returns {string} box product name
     */
    public getBoxProductName(): string {
        return this.getManufacturerName().concat(' ', this.getProductName(this.valuesService.productNameBoxDefault));
    }

    /**
     * Get product name based on language detected
     * @public
     * @memberof ProductsConfigService
     * @param {string} name The product
     * @returns {string} The product name
     */
    public getProductName(name: string): string {
        const lang = this.languageService.getLang();
        let productName = '';

        if (this.productsConfig?.[name]) {
            productName = this.productsConfig?.[name]?.[lang] ?? this.productsConfig[name].default;
        }
        return productName;
    }

    /**
     * Get product name in english
     * @public
     * @memberof ProductsConfigService
     * @param {string} name The product
     * @returns {string} The product name
     */
    public getProductNameEnglish(name: string): string {
        let productName = '';

        if (this.productsConfig?.[name]) {
            productName = this.productsConfig?.[name]?.[this.valuesService.languages.en_US.id] ?? this.productsConfig[name].default;
        }
        return productName;
    }

    /**
     * Get manufacturer name based on language detected
     * @public
     * @memberof ProductsConfigService
     * @returns {string} The manufacturer name
     */
    public getManufacturerName(): string {
        const lang = this.languageService.getLang();
        return this.manufacturerNameConfig?.[lang] ?? this.manufacturerNameConfig.default;
    }

    /**
     * Get manufacturer name in english
     * @public
     * @memberof ProductsConfigService
     * @returns {string} The manufacturer name
     */
    public getManufacturerNameEnglish(): string {
        return this.manufacturerNameConfig?.[this.valuesService.languages.en_US.id] ?? this.manufacturerNameConfig.default;
    }

    /**
     * Get bundle configuration based on bundle type.
     * If the bundle is eligible for sharing and has custom data for family plan return custom values and default ones.
     * If the bundle is not eligible for sharing but has custom data for individual plan return custom values and default ones.
     * If the bundle is not eligible for sharing and has no custom individual values return the default ones, if exists.
     * Otherwise, return null.
     * @public
     * @memberof ProductsConfigService
     * @param {string} bundleId The bundle id
     * @param {boolean} isSharedBundle The eligibility of the subscription to be shared
     * @returns {BundleConfigurationObject} The bundle configuration
     */
    public getBundle(bundleId: string, isSharedBundle: boolean): BundleConfigurationObject {
        if (isSharedBundle && this.bundlesConfig?.[bundleId]?.[BundleConfigurationTypes.FAMILYPLAN]) {
            return {
                ...this.bundlesConfig?.[bundleId]?.[BundleConfigurationTypes.DEFAULT],
                ...this.bundlesConfig?.[bundleId]?.[BundleConfigurationTypes.FAMILYPLAN]
            };
        } else if (this.bundlesConfig?.[bundleId]?.[BundleConfigurationTypes.INDIVIDUAL]) {
            return {
                ...this.bundlesConfig?.[bundleId]?.[BundleConfigurationTypes.DEFAULT],
                ...this.bundlesConfig?.[bundleId]?.[BundleConfigurationTypes.INDIVIDUAL]
            };
        } else {
            return this.bundlesConfig?.[bundleId]?.[BundleConfigurationTypes.DEFAULT] ?? null;
        }
    }

    /**
     * Get bundle name based on level and identified language
     * @public
     * @memberof ProductsConfigService
     * @param {string} bundleId The id of the bundle
     * @param {string} level The level of the app - optional param
     * @param {boolean} isSharedSubscription The eligibility of the subscription to be shared - optional param
     * @returns {string} The bundle name
     */
    public getBundleName(bundleId: string, level?: string, isSharedSubscription = false): string {
        const lang = this.languageService.getLang();
        const bundle = this.getBundle(bundleId, isSharedSubscription);
        return bundle?.name?.[level]?.[lang]
            || bundle?.name?.[level]?.default
            || bundle?.name?.[lang]
            || bundle?.name?.default
            || '';
    }

    /**
     * Get bundle name in english based on level
     * @public
     * @memberof ProductsConfigService
     * @param {string} bundleId The id of the bundle
     * @param {string} level The level of the app - optional param
     * @param {boolean} isSharedSubscription The eligibility of the subscription to be shared - optional param
     * @returns {string} The bundle name
     */
    public getBundleNameEnglish(bundleId: string, level?: string, isSharedSubscription = false): string {
        const lang = this.valuesService.languages.en_US.id;
        const bundle = this.getBundle(bundleId, isSharedSubscription);
        return bundle?.name?.[level]?.[lang]
            || bundle?.name?.[level]?.default
            || bundle?.name?.[lang]
            || bundle?.name?.default
            || '';
    }

    /**
     * Get bundle support name
     * @private
     * @memberof ProductsConfigService
     * @param {string} bundleId The bundle id
     * @returns {string} The bundle support name
     */
    private getBundleSupportName(bundleId: string): string {
        return this.bundlesConfig?.[bundleId]?.[BundleConfigurationTypes.DEFAULT]?.supportName ?? '';
    }

    /**
     * Gets bundle user guide link based on identified language
     * @param {string} bundleId The bundle id
     * @param {boolean} isSharedSubscription The eligibility of the subscription to be shared - optional param
     * @returns {string} The user guide link
     */
    public getBundleUserGuideLink(bundleId: string, isSharedSubscription = false): string {
        const lang = this.languageService.getLang();
        const userGuide = this.getBundle(bundleId, isSharedSubscription)?.userGuide;

        if (userGuide?.[lang]) {
            return userGuide?.[lang];
        }
        
        if (userGuide?.localize && this.valuesService.userGuideLocalizeLang.has(lang)) {
            const localizeLang = lang.split('_')[0].toUpperCase();
            return userGuide.localize.replace('%s', localizeLang);
        }

        return userGuide?.default || '';
    }

    /**
     * Gets bundlewebsite link based on identified language
     * @public
     * @memberof ProductsConfigService
     * @param {string} bundleId The bundle id
     * @param {boolean} isSharedSubscription The eligibility of the subscription to be shared - optional param
     * @returns {string} The website link
     */
    public getBundleWebsiteLink(bundleId: string, isSharedSubscription = false): string {
        const lang = this.languageService.getLang();
        const websiteLink = this.getBundle(bundleId, isSharedSubscription)?.website;
        return websiteLink?.[lang]
            || websiteLink?.default
            || '';
    }

    /**
     * Get png image of a givven app (app images are located in ux/images/subscriptions/bundles)
     * @public
     * @memberof ProductsConfigService
     * @param {App} app The app object
     * @returns {string} The image path
     */
    public getAppImage(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        return this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.IMAGE]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE][AppByLevelProperties.IMAGE]
                || '';
    }

    /**
     * Get description of app
     * @public
     * @memberof ProductsConfigService
     * @param app The app object
     * @returns {string} The app description
     */
    public getAppDescription(app: App): string{
        const appId = app.app_id;
        const level = app?.app_params?.level;
        return this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.DESCRIPTION]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE][AppByLevelProperties.DESCRIPTION]
                || '';
    }

    /**
     * Get product to install for a given app
     * @public
     * @memberof ProductsConfigService
     * @param {string} appId The app id
     * @returns {ProductsToInstall} The product to install (Protection, VPN, etc) deppending on AppByLevelProperties
     */
    public getAppProductToInstall(appId: string): ProductsToInstall {
        return this.appByLevelInfo?.[appId]?.[AppLevels.NONE][AppByLevelProperties.PRODUCT];
    }

    /**
     * Get app name based on level, identified language and subscriptions version
     * @public
     * @memberof ProductsConfigService
     * @param {App} app The app object
     * @returns {string} The app name
     */
    public getAppName(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        const lang = this.languageService.getLang();
        const name = !this.profilesService.ownerHasSubsV4() ? AppByLevelProperties.NAME_V3 : AppByLevelProperties.NAME;

        return this.appByLevelInfo?.[appId]?.[level]?.[name]?.[lang]
                || this.appByLevelInfo?.[appId]?.[level]?.[name]?.[AppByLevelProperties.DEFAULT]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[name]?.[lang]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[name]?.[AppByLevelProperties.DEFAULT]
                || '';
    }

     /**
     * Get app name for subscriptions version v4 based on level and identified language
     * @public
     * @memberof ProductsConfigService
     * @param {App} app The app to get the V4 app name for
     * @returns {string} app name
     */
     public getV4AppName(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        const lang = this.languageService.getLang();

        return this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.NAME]?.[lang]
                || this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.NAME]?.[AppByLevelProperties.DEFAULT]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.NAME]?.[lang]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.NAME]?.[AppByLevelProperties.DEFAULT]
                || '';
    }

    /**
     * Gets the first part of the app name for the new install modal
     * @public
     * @memberof ProductsConfigService
     * @param {App} app The app to get the upper install app name for
     * @returns {string} The first part of the app name
     */
    public getUpperInstallAppName(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        const lang = this.languageService.getLang();
        return this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[lang]?.[AppByLevelProperties.UPPER]
                || this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[AppByLevelProperties.DEFAULT]?.[AppByLevelProperties.UPPER]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[lang]?.[AppByLevelProperties.UPPER]
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[AppByLevelProperties.DEFAULT]?.[AppByLevelProperties.UPPER]
                || '';
    }

    /**
     * Gets the second part of the app name for the new install modal
     * @public
     * @memberof ProductsConfigService
     * @param {App} app The app to get the upper install app name for
     * @returns {string} The second part of the app name
     */
    public getLowerInstallAppName(app: App): string {
        const appId = app.app_id;
        const level = app?.app_params?.level;
        const lang = this.languageService.getLang();
        return this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[lang]?.[AppByLevelProperties.LOWER]()
                || this.appByLevelInfo?.[appId]?.[level]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[AppByLevelProperties.DEFAULT]?.[AppByLevelProperties.LOWER]()
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[lang]?.[AppByLevelProperties.LOWER]()
                || this.appByLevelInfo?.[appId]?.[AppLevels.NONE]?.[AppByLevelProperties.INSTALL_MODAL_NAME]?.[AppByLevelProperties.DEFAULT]?.[AppByLevelProperties.LOWER]()
                || '';
    }

    /**
     * Get appInfo with updated product name
     * @public
     * @memberof ProductsConfigService
     * @param {string} appId
     * @returns {object} app info
     */
    public getDevicesAppInfo(appId: string) {
        const {nameFunction, ...appInfoNoFunction} = this.devicesAppsConfig[appId];
        appInfoNoFunction.name = this.devicesAppsConfig[appId].nameFunction();
        return appInfoNoFunction;
    }

}
