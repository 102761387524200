// External
import { Injectable } from '@angular/core';
import { Observable, of, map, catchError } from 'rxjs';

// Internal
import { PrivacyActionsService } from '../../pages/privacy/privacyActions.service';
import { AdobeDataLayerService } from '../services/core/adobe.datalayer.service';
import { SourceTypes, CampaignNames, CampaignMediaIdentifiers, CampaignParameters } from '../services/core/AdobeParams.model';
import { LanguageService } from '../services/core/language.service';
import { UsefulService } from '../services/global/useful/useful.service';
import { CommercialidsService } from '../services/process/commercialids/commercialIds.service';
import { ProfilesService } from '../services/process/profiles/profiles.service';
import { SubscriptionsService } from '../services/process/subscriptions/subscriptions.service';
import { RenewalInfoService } from '../services/requests/connect-renewal-info-service/connect-renewal-info.service';
import { PrivacyValuesService } from '../values/privacy.values.service';
import { SubscriptionsValuesService } from '../values/subscriptions.values.service';
import { ValuesService } from '../values/values.service';

@Injectable({
    providedIn: 'root'
})
export class BuyLinksService {

    buyTsSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/total-security.html';
    buyTsMultiDeviceSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/total-security-multi-device.html';
    buyAvForMacSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/antivirus-for-mac.html';
    buyAvSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/antivirus.html';
    buyIsSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/internet-security.html';
    buyBmsSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/mobile-security-android.html';
    buyIsMultiDeviceSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/internet-security-multi-device.html';
    buyFamillyPackSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/family-pack.html';
    buyPremiumVPNSubscriptionDefaultLink = 'https://www.bitdefender.com/links/en-US/VPN/upgrade_premium.html';
    buyPassManagerSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/password-manager.html';
    buyPremiumSecuritySubscriptionDefaultLink = 'https://www.bitdefender.com/buy/premium-security.html';
    buyBisSubscriptionDefaultLink = 'https://www.bitdefender.com/buy/mobile-security-ios.html';
    buyIdtheftStandardSubscriptionDefaultLink = 'https://www.bitdefender.com/site/Store/buy/idtheftsm/1/1/pid.idtheftm_central_renewal';
    buyIdtheftPremiumSubscriptionDefaultLink = 'https://www.bitdefender.com/site/Store/buy/idtheftpm/1/1/pid.idtheftmpremium_central_renewal';
    buyUsSubscriptionDefaultLink = 'https://www.bitdefender.com/site/Store/buy/ultsecm/1/1/pid.ultimatem_central_renewal';
    buyUsPlusSubscriptionDefaultLink = 'https://www.bitdefender.com/site/Store/buy/ultsecplusm/1/1/pid.ultimatemplus_central_renewal';

    buyTsCommercialKey = 'buyts_url';
    buyAvForMacCommercialKey = 'buymac_url';
    buyAvCommercialKey = 'buyav_url';
    buyIsCommercialKey = 'buyis_url';

    buyLinkValues = {
        dip: {
            billingCyclePlaceholder: '{{dipBillingCycle}}',
            yearlyValue: 'dip',
            monthlyValue: 'dipm'
        },
        premiumSecurityPlus: {
            billingCyclePlaceholder: '{{premiumSecurityPlusBillingCycle}}',
            yearlyValue: 'psp',
            monthlyValue: 'pspm'
        }
    };

    /**
     * commercialUrl : the key from commercial info for every bundle
     * default : default buy link if commercial key was not found in commercial info
     */
    buyLinks = {
        [this.valuesService.bundlePA]: {
            commercialUrl: this.buyTsCommercialKey,
            defautl: this.buyTsSubscriptionDefaultLink
        },
        [this.valuesService.bundlePANCC]: {
            commercialUrl: this.buyTsCommercialKey,
            defautl: this.buyTsSubscriptionDefaultLink
        },
        [this.valuesService.bundleAVFM]: {
            commercialUrl: this.buyAvForMacCommercialKey,
            default: this.buyAvForMacSubscriptionDefaultLink
        },
        [this.valuesService.bundleAV]: {
            commercialUrl: this.buyAvCommercialKey,
            default: this.buyAvSubscriptionDefaultLink
        },
        [this.valuesService.bundleAVNEW]: {
            commercialUrl: this.buyAvCommercialKey,
            default: this.buyAvSubscriptionDefaultLink
        },
        [this.valuesService.bundleIS]: {
            commercialUrl: this.buyIsCommercialKey,
            default: this.buyIsSubscriptionDefaultLink
        },
        [this.valuesService.bundleTS]: {
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyTsSubscriptionDefaultLink
        },
        [this.valuesService.bundleTS]: {
            default: this.buyTsSubscriptionDefaultLink
        },
        [this.valuesService.bundleBMS]: {
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyBmsSubscriptionDefaultLink
        },
        [this.valuesService.bundleTSMD]: {
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyTsMultiDeviceSubscriptionDefaultLink
        },
        [this.valuesService.bundleTSMDNEW]: {
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyTsMultiDeviceSubscriptionDefaultLink
        },
        [this.valuesService.bundleISMD]: {
            commercialUrl: this.buyIsCommercialKey,
            default: this.buyIsMultiDeviceSubscriptionDefaultLink
        },
        [this.valuesService.bundleFP]: {
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyFamillyPackSubscriptionDefaultLink
        },
        [this.valuesService.bundleFPP]: {
            default: this.buyFamillyPackSubscriptionDefaultLink
        },
        [this.valuesService.bundleFPAV]: {
            commercialUrl: this.buyAvCommercialKey,
            default: this.buyAvSubscriptionDefaultLink
        },
        [this.valuesService.bundleFPIS]: {
            commercialUrl: this.buyIsCommercialKey,
            default: this.buyIsSubscriptionDefaultLink
        },
        [this.valuesService.bundleVPN]: {
            default: this.buyPremiumVPNSubscriptionDefaultLink
        },
        [this.valuesService.bundleVPNSOHO]: {
            default: this.buyPremiumVPNSubscriptionDefaultLink
        },
        [this.valuesService.bundleBOX2]:{
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyTsSubscriptionDefaultLink
        },
        [this.valuesService.bundleStartPlan]: {
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyTsSubscriptionDefaultLink
        },
        [this.valuesService.bundlePersonalPlan]: {
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyTsSubscriptionDefaultLink
        },
        [this.valuesService.bundleFamilyPlan]: {
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyTsSubscriptionDefaultLink
        },
        [this.valuesService.bundleUltimatePlan]: {
            commercialUrl: this.buyTsCommercialKey,
            default: this.buyTsSubscriptionDefaultLink
        },
        [this.valuesService.bundleTSVPN]: {
            default: this.buyTsMultiDeviceSubscriptionDefaultLink
        },
        [this.valuesService.bundleSOHO]: {
            default: this.buyTsMultiDeviceSubscriptionDefaultLink
        },
        [this.valuesService.bundleDPI]: {
            [this.valuesService.languages.en_GB.id]: `https://www.bitdefender.co.uk/site/Store/buy/${this.buyLinkValues.dip.billingCyclePlaceholder}/1/1/pid.dip_central_renewal`,
            [this.valuesService.languages.en_AU.id]: `https://www.bitdefender.com.au/site/Store/buy/${this.buyLinkValues.dip.billingCyclePlaceholder}/1/1/pid.dip_central_renewal`,
            [this.valuesService.languages.fr_FR.id]: `https://www.bitdefender.fr/site/Store/buy/${this.buyLinkValues.dip.billingCyclePlaceholder}/1/1/pid.dip_central_renewal`,
            [this.valuesService.languages.fr_CA.id]: `https://www.bitdefender.fr/site/Store/buy/${this.buyLinkValues.dip.billingCyclePlaceholder}/1/1/pid.dip_central_renewal`,
            default: `https://www.bitdefender.com/site/Store/buy/${this.buyLinkValues.dip.billingCyclePlaceholder}/1/1/pid.dip_central_renewal`
        },
        [this.valuesService.bundlePM]: {
            default: this.buyPassManagerSubscriptionDefaultLink
        },
        [this.valuesService.bundlePSec]: {
            default: this.buyPremiumSecuritySubscriptionDefaultLink
        },
        [this.valuesService.bundlePSecNew]: {
            default: this.buyPremiumSecuritySubscriptionDefaultLink
        },
        [this.valuesService.bundlePSecPlus]: {
            [this.valuesService.languages.en_AU.id]: 'https://www.bitdefender.com.au/site/Store/buy/'.concat(this.buyLinkValues.premiumSecurityPlus.billingCyclePlaceholder,
                                                                                                    '/10/1/pid.',
                                                                                                    this.buyLinkValues.premiumSecurityPlus.billingCyclePlaceholder,
                                                                                                    '_central_renewal'),
            [this.valuesService.languages.de_DE.id]: 'https://www.bitdefender.de/site/Store/buy/'.concat(this.buyLinkValues.premiumSecurityPlus.billingCyclePlaceholder,
                                                                                                    '/10/1/pid.',
                                                                                                    this.buyLinkValues.premiumSecurityPlus.billingCyclePlaceholder,
                                                                                                    '_central_renewal'),
            [this.valuesService.languages.fr_FR.id]: 'https://www.bitdefender.fr/site/Store/buy/'.concat(this.buyLinkValues.premiumSecurityPlus.billingCyclePlaceholder,
                                                                                                    '/10/1/pid.',
                                                                                                    this.buyLinkValues.premiumSecurityPlus.billingCyclePlaceholder,
                                                                                                    '_central_renewal'),
            default: 'https://www.bitdefender.co.uk/site/Store/buy/'.concat(this.buyLinkValues.premiumSecurityPlus.billingCyclePlaceholder,
                                                                        '/10/1/pid.',
                                                                        this.buyLinkValues.premiumSecurityPlus.billingCyclePlaceholder,
                                                                        '_central_renewal')
        },
        [this.valuesService.bundleBIP]: {
            default: this.buyBisSubscriptionDefaultLink
        },
        [this.valuesService.bundleBIS]: {
            default: this.buyBisSubscriptionDefaultLink
        },
        [this.valuesService.bundleIDTPP]: {
            default: this.buyIdtheftPremiumSubscriptionDefaultLink
        },
        [this.valuesService.bundleIDTPS]: {
            default: this.buyIdtheftStandardSubscriptionDefaultLink
        },
        [this.valuesService.bundleUSPUS]: {
            default: this.buyUsPlusSubscriptionDefaultLink
        },
        [this.valuesService.bundleUSPUSNEW]: {
            default: this.buyUsPlusSubscriptionDefaultLink
        },
        [this.valuesService.bundleUSUS]: {
            default: this.buyUsSubscriptionDefaultLink
        },
        [this.valuesService.bundleUSUSNEW]: {
            default: this.buyUsSubscriptionDefaultLink
        },
        [this.valuesService.bundleUSEUNEW]: {
            default: this.buyUsSubscriptionDefaultLink
        }
    };

    defaultBuyLink = 'https://www.bitdefender.com/buy/all.html';
    buyAllBitdefenderLink = 'https://www.sourcenext.com/security/rd/bd/106.html';
    langPlaceholder = '{{lang}}';
    appIdPlaceholder = '{{appId}}';
    vpnUpsellLink = `https://www.bitdefender.com/links/${this.langPlaceholder}/VPN/upgrade_premium.html?app_id=${this.appIdPlaceholder}&optype=1`;
    buyLinkCentralWebSep = 'central_web';

    productSolutionLinks = {
        [this.valuesService.productNamePS]: this.buyAllBitdefenderLink.concat('premium-security.html'),
        [this.valuesService.productNameTS]: this.buyAllBitdefenderLink.concat('total-security.html'),
        [this.valuesService.productNameDIP]: this.buyAllBitdefenderLink.concat('digital-identity-protection.html'),
        [this.valuesService.productNameVPNBasic]: this.buyAllBitdefenderLink.concat('vpn.html'),
        [this.valuesService.productNameIDTP]: this.buyAllBitdefenderLink.concat('identity-theft-protection.html'),
        [this.valuesService.productNamePasswordManager]: this.buyAllBitdefenderLink.concat('password-manager.html')
    };

    constructor(
        private readonly languageService            : LanguageService,
        private readonly valuesService              : ValuesService,
        private readonly usefulService              : UsefulService,
        private readonly commercialIdsService       : CommercialidsService,
        private readonly subscriptionsValuesService : SubscriptionsValuesService,
        private readonly adobeDataLayerService      : AdobeDataLayerService,
        private readonly subscriptionsService       : SubscriptionsService,
        private readonly profilesService            : ProfilesService,
        private readonly renewalInfoService         : RenewalInfoService,
        private readonly privacyActionsService      : PrivacyActionsService,
        private readonly privacyValuesService       : PrivacyValuesService
    ) {}

    /**
     * Computes 'source' for renew links
     * @param bundle
     * @returns
     */
    public computeRenewSource(bundle): SourceTypes {
        if (this.subscriptionsService.isVPNSubscription(bundle)) {
            if (this.subscriptionsService.isVpnPremium(bundle)) {
                return SourceTypes.RENEW_CENTRAL;
            } else {
                return SourceTypes.UPSELL_CENTRAL;
            }
        }
        return SourceTypes.EMPTY;
    }

    /**
     * Computes 'campaing' for renew links
     * @param bundle
     * @returns
     */
    public computeRenewCampaign(bundle): CampaignNames {
        if (this.subscriptionsService.isDpy(bundle)) {
            return CampaignNames.DIP_RENEW;
        } else if (this.subscriptionsService.isPasswordManager(bundle)) {
            return CampaignNames.PASSWORD_MANAGER_RENEW;
        } else if (this.subscriptionsService.isIdTheftProtection(bundle)) {
            return CampaignNames.IDTHEFT_RENEW;
        } else if (this.subscriptionsService.isUltimateSecurityProtection(bundle)) {
            return CampaignNames.ULTIMATE_RENEW;
        } else if (this.subscriptionsService.isVpnPremium(bundle)) {
            return CampaignNames.VPN_RENEW;
        } else if (bundle.type === this.subscriptionsValuesService.type.trial) {
            return CampaignNames.WELCOME_BUY;
        }
        return CampaignNames.CORE_RENEW;
    }

    /**
     * Makes a request to retrieve the buy more link and then appends all needed parameters to it
     * For v3 does not make the requests and computes the link with the info currently available
     * @param bundle Bundle that contains bundle_id and/or service_id or empty object
     * @param buyMore true if the link computed is for buy more buttons, false otherwise
     * @param cidOrIcidCampaign Campaign name
     * @param source 'source' param
     * @param identifier Media id, button or link
     * @returns
     */
    public getBuyMoreLinkFromBackend(bundle, buyMore: boolean, cidOrIcidCampaign: CampaignNames, source: SourceTypes, identifier: CampaignMediaIdentifiers): Observable<any> {
        // v3 subscriptions
        if (!this.profilesService.ownerHasSubsV4()) {
            return of(this.getStaticOrCommercialBuyLink(bundle, buyMore, source, '', true, cidOrIcidCampaign, identifier));
        }

        // v4 subscriptions
        return this.renewalInfoService.getUpgradeLink(bundle?.service_id)
        .pipe(
            map(resp =>
                // 'bundle' o sa fie obiect gol '{}' pentru ca nu mai avem nevoie de parametrii astia in link pt ca linkul
                // redirecteaza direct in store, cu produsul adaugat deja in cos
                // asta pentru ca trimiti in connect info despre bundle si nu mai e nevoie sa le adaugi in url
                this.getStaticOrCommercialBuyLink({}, buyMore, source, resp, true, cidOrIcidCampaign, identifier)

            ),
            catchError(() => of(this.getStaticOrCommercialBuyLink(bundle, buyMore, source, '', true, cidOrIcidCampaign, identifier)))
        );
    }

    /**
     * Computes the buy/buy more/renew link based on all of the given parameters
     * All parameters can be configurable to fit every possible scenario
     * Computes the link based on static info and commercial info, adds extra query params,
     * adds cid/icid and adds ref and visitor to the final link
     * @param bundle Bundle that contains bundle_id and/or service_id or empty object
     * @param buyMore true if the link computed is for buy more buttons, false otherwise
     * @param source 'source' param
     * @param link buy link
     * @param internal true if it is icid, false if it is cid
     * @param cidOrIcidCampaign Campaign name
     * @param mediaId Media id, button or link
     * @returns Static or commercial buy more/buy/renew link
     */
    public getStaticOrCommercialBuyLink(bundle,
                                buyMore: boolean,
                                source: SourceTypes,
                                link: string,
                                internal: boolean,
                                cidOrIcidCampaign: CampaignNames,
                                mediaId: CampaignMediaIdentifiers) {
        const adobeParams: CampaignParameters = this.computeAdobeParams(internal, cidOrIcidCampaign, mediaId);
        link = this.getStaticOrCommercialBuyLinkNoAdobe(bundle, buyMore, source, link);
        return this.adobeDataLayerService.addCidOrIcidRefAndVisitor(link, adobeParams);
    }

    private getStaticOrCommercialBuyLinkNoAdobe(bundle, buyMore: boolean, source: SourceTypes, link: string) {
        if (!link) {
            if (buyMore) {
                link = this.computeStaticOrCommercialBuyMoreLink(bundle);
            } else {
                link = this.computeStaticOrCommercialBuyLink(bundle);
            }
        }
        return this.addExtraQueryParamsForBuyLink(bundle, link, source);
    }

    private computeAdobeParams(internal: boolean, cidOrIcidCampaign: CampaignNames, mediaId: CampaignMediaIdentifiers) {
        const adobeParams: CampaignParameters = {
            internal,
            mediaId
        };

        if (cidOrIcidCampaign) {
            adobeParams.campaignName = cidOrIcidCampaign;
        }

        return adobeParams;
    }

    /**
     * Searches the buy more link in commercial info, if it's not found, then returns default link
     * @param bundle
     * @returns Buy more link
     */
    private computeStaticOrCommercialBuyMoreLink(bundle) {
        let buyLink = '';

        const auxBuyLink = this.commercialIdsService.getCommercialBuyLink(bundle?.commercial_id, 'buyadditional_url');
        if (auxBuyLink) {
            buyLink = auxBuyLink;
        } else {
            buyLink = this.defaultBuyLink;
        }
        return buyLink;
    }

    /**
     * Seaches for buy link in commercial info, if it's not found, then returns a default link
     * @param bundle
     * @returns Buy link
     */
    private computeStaticOrCommercialBuyLink(bundle) {
        let buyLink = '';
        const lang = this.languageService.getLang();
        const buyLinkProperties = this.buyLinks[bundle.bundle_id];
        if (!buyLinkProperties) {
            return this.defaultBuyLink;
        }

        const auxBuyLink = this.commercialIdsService.getCommercialBuyLink(bundle?.commercial_id, buyLinkProperties.commercialUrl);
        if (auxBuyLink) {
            buyLink = auxBuyLink;
        } else if (bundle.bundle_id === this.valuesService.bundleDPI) {
            buyLink = this.computeHardcodedDpiLink(bundle);
        } else if (bundle.bundle_id === this.valuesService.bundlePSecPlus) {
            buyLink = this.computeHardcodedPremiumSecurityPlusLink(bundle);
        } else {
            const localizedBuyLink = this.buyLinks[bundle.bundle_id]?.[lang];
            if (localizedBuyLink) {
                buyLink = localizedBuyLink;
            } else {
                buyLink = this.buyLinks[bundle.bundle_id]?.default ? this.buyLinks[bundle.bundle_id]?.default : this.defaultBuyLink;
            }
        }
        return buyLink;
    }

    /**
     * Adds query params to buy links based on the parameters provided
     * @param bundle
     * @param buyLink
     * @param source
     * @returns
     */
    private addExtraQueryParamsForBuyLink(bundle, buyLink, source: SourceTypes) {
        const lang = this.languageService.getLang();
        const params:any = {
            sep: this.buyLinkCentralWebSep,
            lang
        };

        if (bundle.bundle_id) {
            params.bundle_id = bundle.bundle_id;
        }

        if (bundle.service_id) {
            params.service_id = bundle.service_id;
        }

        if (bundle.type) {
            params.t = bundle.type;
        }

        if (bundle.service_id) {
            params.service_id = bundle.service_id;
        }

        const days = this.daysSinceActivation(bundle);
        if (days) {
            params.d = days;
        }

        if (source) {
            params.source = source;
        }

        if (buyLink.indexOf('?') !== -1) {
            buyLink += `&${this.usefulService.jsonToQueryString(params)}`;
        } else {
            buyLink += `?${this.usefulService.jsonToQueryString(params)}`;
        }

        return buyLink;
    }

    private daysSinceActivation(bundle) {
        if (bundle.server_time && bundle.created) {
            return parseInt(((bundle.server_time - bundle.created) / (24*3600)).toString());
        }
        return '';
    }

    private computeHardcodedDpiLink(bundle) {
        let buyLink = '';
        const lang = this.languageService.getLang();
        const dipBillingCycle = bundle?.metadata?.billing_cycle === this.subscriptionsValuesService.year
                                ? this.buyLinkValues.dip.yearlyValue
                                : this.buyLinkValues.dip.monthlyValue;

        if (dipBillingCycle) {
            buyLink = this.buyLinks?.[this.valuesService.bundleDPI]?.[lang];
            if (!buyLink) {
                buyLink = this.buyLinks?.[this.valuesService.bundleDPI]?.default;
            }
        }
        if (buyLink) {
            return buyLink.replace(this.buyLinkValues.dip.billingCyclePlaceholder, dipBillingCycle);
        }
        return this.defaultBuyLink;
    }

    /**
     * Function that computes the buy link for premium security plus based on user's language.
     * If replaces all placeholders and returns the custom buy link for lang, or the default value for given bundle.
     * If there is no buy link specified for given bundle it returns the default buy link.
     * @param {object} bundle
     * @returns {string} Buy link
     */
    private computeHardcodedPremiumSecurityPlusLink(bundle) {
        let buyLink = '';
        const lang = this.languageService.getLang();
        const premiumSecurityPlusBillingCycle = bundle?.metadata?.billing_cycle === this.subscriptionsValuesService.year
                                                ? this.buyLinkValues.premiumSecurityPlus.yearlyValue
                                                : this.buyLinkValues.premiumSecurityPlus.monthlyValue;

        if (premiumSecurityPlusBillingCycle) {
            buyLink = this.buyLinks?.[this.valuesService.bundlePSecPlus]?.[lang];
            if (!buyLink) {
                buyLink = this.buyLinks?.[this.valuesService.bundlePSecPlus]?.default;
            }
        }
        if (buyLink) {
            return buyLink.split(this.buyLinkValues.premiumSecurityPlus.billingCyclePlaceholder).join(premiumSecurityPlusBillingCycle);
        }
        return this.defaultBuyLink;
    }

    public getBuyAllLink() {
        return this.getStaticOrCommercialBuyLink({},
                                                false,
                                                SourceTypes.EMPTY,
                                                this.buyAllBitdefenderLink,
                                                true,
                                                CampaignNames.WELCOME_BUY,
                                                CampaignMediaIdentifiers.BUTTON);
    }

    public getDipRenewPageLink() {
        const langCategory = this.privacyActionsService.getLanguageCategory();
        const specificLang = this.languageService.getLang();
        const defaultLangCategory = this.privacyValuesService.languageGroups.en;
        let link = '';

        if (this.privacyValuesService?.specificStaticLinks[specificLang]?.renew_subscription) {
            link = this.privacyValuesService.specificStaticLinks[specificLang].renew_subscription;
        } else if (this.privacyValuesService?.staticLinks[langCategory]?.renew_subscription) {
            link = this.privacyValuesService.staticLinks[langCategory].renew_subscription;
        } else {
            link = this.privacyValuesService?.staticLinks[defaultLangCategory]?.renew_subscription;
        }

        return this.getStaticOrCommercialBuyLink({}, false, SourceTypes.EMPTY, link, true, CampaignNames.DIP_DASHBOARD, CampaignMediaIdentifiers.LINK);
    }

    public upsellLinkVpn(serviceId, bundleId, campaignName: CampaignNames, modifier: CampaignMediaIdentifiers) {
        const lang = this.languageService.getLang().replace('_', '-');
        let newBuyLink = this.vpnUpsellLink.replace(this.langPlaceholder, lang);
        newBuyLink = newBuyLink.replace(this.appIdPlaceholder, this.valuesService.appVPN);

        const bundle: any = {};
        if (bundleId) {
            bundle.bundle_id = bundleId;
        }

        if (serviceId) {
            bundle.service_id = serviceId;
        }

        return this.getStaticOrCommercialBuyLink(bundle, false, SourceTypes.CENTRAL, newBuyLink, true, campaignName, modifier);
    }

    public getDefaultBuyLink() {
        return this.adobeDataLayerService.addRefAndVisitor(this.defaultBuyLink);
    }

    /**
     * Returns the computed product solution link based on the given parameters,
     * adds custom extra params (ex: a lang param if needed)
     * and adds cid/icid if campaign params are givven
     * @param productName the solution's product name
     * @param extraParams extra params to add
     * @param campaignParams campaign speciffic params to add
     * @returns parametrized link from productSolutionLinks
     */
    getProductSolutionLink(productName, extraParams?, campaignParams?: CampaignParameters) {
        let link = this.productSolutionLinks[productName];
        if (extraParams) {
            if (link.indexOf('?') !== -1) {
                link = link.concat(`&${this.usefulService.jsonToQueryString(extraParams)}`);
            } else {
                link = link.concat(`?${this.usefulService.jsonToQueryString(extraParams)}`);
            }
        }

        if (campaignParams) {
            link = this.adobeDataLayerService.addCidOrIcidRefAndVisitor(link, campaignParams);
        }

        return link;
    }

}
