export enum AccountSetting {
    showTwoFaAlert = 'showTwoFaAlert',
    showMasterPasswordAlert = 'showMasterPasswordAlert',
    boarded = 'boarded',
    onbordingComplete = 'onbordingComplete',
    snapPhoto = 'snapPhoto',
    device_list_banner_appearance = 'device_list_banner_appearance',
    showSharedSubscriptionAlert = 'showSharedSubscriptionAlert',
    securityWasAccessed = 'securityWasAccessed'
}

export interface AccountSettings {
    [AccountSetting.showTwoFaAlert]?: boolean;
    [AccountSetting.showMasterPasswordAlert]?: boolean;
    [AccountSetting.boarded]?: boolean;
    [AccountSetting.onbordingComplete]?: boolean;
    [AccountSetting.snapPhoto]?: boolean;
    [AccountSetting.device_list_banner_appearance]?: string;
    [AccountSetting.showSharedSubscriptionAlert]?: boolean;
    [AccountSetting.securityWasAccessed]?: boolean;
}

export enum NccSetting {
    ncc_pin = 'ncc_pin'
}

export interface NccSettings {
    [NccSetting.ncc_pin]?: number;
}