// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tv4 } from 'tv4';

// Internal
import { RequestsService } from '../../global/request-service/requests.service';
import { ValuesService } from '../../../values/values.service';
import { schemas } from 'src/app/common/models/schemas';
import { UtilsCommonService } from 'src/app/common/utils/utils-common.service';
import { ConfigService } from 'src/app/common/config/config.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConnectNotificationsService {
    constructor(
        private cookieService: CookieService,
        private requestsService: RequestsService,
        private utilsCommonService: UtilsCommonService,
        private valuesService: ValuesService,
        private configService: ConfigService
    ) { }

    /**
     * This method is used to return the number of unread notifications that the requesting user has
     * @returns Response from server
     */
    countNotifications(): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'count',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectnotificationsService,
            json,
            'POST'
        ).pipe(
            map(
                (res: any) => {
                    return res.result;
                }
            ),
            catchError((error) => {
                throw error;
            }),
        );
    }

    /**
     * This method returns the notifications list
     * @param {object} Contains mandatory `vars`
     * @returns Response from server
     */
    getNotifications(vars): Observable<any> {
        if (
            this.utilsCommonService.isEmptyObject(vars) ||
            !this.utilsCommonService.isObject(vars)
        ) {
            console.error('Invalid params', tv4.error);
            return of('Invalid params');
        }
        let json: any = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'list',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        if (vars.unread) {
            json.params.filter = {};
            json.params.filter.unread = vars.unread;
        }
        if (vars.offset || vars.offset === 0) {
            json.params.paging = {};
            json.params.paging.count = vars.count;
            json.params.paging.offset = vars.offset;
        }

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectnotificationsService,
            json,
            'POST'
        ).pipe(
            map(
                resp => {
                    return resp.result;
                }
            ),
            catchError((err) => {
                throw err;
            })
        );
    }

    /**
     * This method is used to mark as read one or multiple notifications that belong to the requesting user
     * @param {object} Contains mandatory `id`
     * @returns Response from server
     */
    readNotification(id): Observable<any> {
        if (!tv4.validate(id, schemas.schemaUuid)) {
            console.error('Invalid params', tv4.error);
            return of('Invalid params');
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'ack',
            params: {
                notification_id: id,
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectnotificationsService,
            json,
            'POST'
        );
    }

    /**
     * This method is used to mark all notifications as read. Method will affect only notifications that belong to requesting user
     * @returns Response from server
     */
    readAllNotifications(): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'mark_all_as_read',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectnotificationsService,
            json,
            'POST'
        );
    }

    /**
      * This method is used to permanently delete all notifications that belong to the requesting user
      * @returns Response from server
      */
    deleteNotifications(): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'clear_all',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };
        return this.requestsService.make(this.configService.config.nimbusServer, this.valuesService.connectnotificationsService, json, 'POST');
    }

    /**
     * This method is used to permanently delete one notification that belong to the requesting user
     * @returns Response from server
     */
    deleteSingleNotification(notification_id): Observable<any> {
        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'clear',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                id: notification_id
            }
        };
        return this.requestsService.make(this.configService.config.nimbusServer, this.valuesService.connectnotificationsService, json, 'POST');
    }

    getNotificationById(arrOfIds): Observable<any> {
        if (this.utilsCommonService.checkArray(arrOfIds)) {
            for (let i = 0; i < arrOfIds.length; i++) {
                if (!tv4.validate(arrOfIds[i], schemas.schemaUuid)) {
                    console.error('Invalid params', tv4.error);
                    return of('Invalid params');
                }
            }
        }
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'get_info_by_id',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                ids: arrOfIds
            }
        };

        return this.requestsService.make(
            this.configService.config.nimbusServer,
            this.valuesService.connectnotificationsService,
            json,
            'POST'
        ).pipe(
            map((resp) => {
                return resp.result;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    threatsInfoBatch(notifications): Observable<any> {
        let arr = [];
        for (let i = 0; i < notifications.length; i++) {
            let json = {
                id: i + 1,
                jsonrpc: this.valuesService.jsonrpc,
                method: 'get_group_threat_info',
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.connectAppId
                    },
                    threats_group_id: notifications[i].threat_group_id || notifications[i].app_fields.notification_params.threat_group_id,
                    threats_count: notifications[i].threats_count || notifications[i].app_fields.notification_params.threats_count
                }
            };

            // put in request array only if we have threat_group_id
            if (notifications[i].threat_group_id || notifications[i].app_fields.notification_params.threat_group_id) {
                arr.push(json);
            }
        }

        if (arr.length === 0) {
            return of([]);
        }

        return this.requestsService.make(this.configService.config.nimbusServer, this.valuesService.secCenter, arr, 'POST')
            .pipe(
                map(
                    resp => {

                        // if (resp.status && resp.status === 'error') {
                        //     console.log('error listThreatsInfo', resp);
                        //     return;
                        // }

                        // let temp = [];
                        // if (Array.isArray(resp)) {
                        //     for (let k in resp) {
                        //         temp.push(resp[k]);
                        //     }
                        // } else {
                        //     temp = resp;
                        // }

                        // for (let l in resp) {
                        //     for (let t in notifs) {
                        //         if (notifs[t].app_fields.notification_id === threats[l].notification_id) {
                        //             notifs[t].app_fields.notification_params.threat_details = resp[l];
                        //         }
                        //     }
                        // }
                        let temp_resp = resp[0].result;
                        return temp_resp;
                    }
                ),
                catchError((err) => {
                    return err;
                })
            );

    }

    setException(data): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'set_ghostr_exception',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: data.box_id,
                    app_id: 'com.bitdefender.boxse'
                },
                threat_id: data.threat_id,
                device_id: data.device_id,
                allow: 1
            }
        };

        return this.requestsService.make(this.configService.config.nimbusServer, this.valuesService.connectMgmtService, json, 'POST');
    }

    blockDevice(info): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: 'set_traffic_status',
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                connect_destination: {
                    device_id: info.device_id
                },
                blocked: info.nb
            }
        };

        return this.requestsService.make(this.configService.config.nimbusServer, this.valuesService.connectMgmtService, json, 'POST');
    }
}
