// External
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

// Internal
import { ValuesService } from '../../../values/values.service';
import { ConnectMgmtService } from '../../requests/connect-mgmt-service/connect-mgmt.service';
import { AppConfigurationStatus } from '../../../models/Services.model';

@Injectable({
    providedIn: 'root'
})

export class PasswordManagerService {

    private readonly onListSaferpassStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);
    private markToUpdateSaferpassStatus = true;

    private saferpassUserRegistered: boolean = false;
    private appConfigurationStatus: AppConfigurationStatus = AppConfigurationStatus.PENDING;

    constructor(
        private readonly valuesService: ValuesService,
        private readonly connectMgmtService: ConnectMgmtService
    ) { }

    /**
     * List Saferpass Status for Password Manager
     *
     * @public
     * @return {*}  {Observable<any>}
     * @memberof PasswordManagerService
     */
    public listSaferpassStatus(): Observable<any> {
        if (!this.markToUpdateSaferpassStatus){
            return of(this.saferpassUserRegistered);
        }

        if (this.onListSaferpassStatus$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onListSaferpassStatus$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onListSaferpassStatus$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.connectMgmtService.listSaferpassStatus()
            .pipe(
                map(resp => {
                    this.saferpassUserRegistered = resp.user_registered;
                    this.appConfigurationStatus = resp.user_registered ? AppConfigurationStatus.CONFIGURED : AppConfigurationStatus.NOT_CONFIGURED;
                    this.markToUpdateSaferpassStatus = false;
                    this.onListSaferpassStatus$.next(this.valuesService.processServiceState.DONE);
                    return of(true);
                }),
                catchError(err => {
                    this.appConfigurationStatus = AppConfigurationStatus.NOT_CONFIGURED
                    this.markToUpdateSaferpassStatus = true;
                    this.onListSaferpassStatus$.next(this.valuesService.processServiceState.DONE);
                    throw err;
                }),
            );
        }
    }

    /**
     * Get Saferpass Status for Password Manager     *
     * @public
     * @memberof PasswordManagerService
     * @return {boolean} the status of user_registered, true if user is registered (has master passowrd set) false otherwise
     */
    public getSaferpassUserRegistered(): boolean {
        return this.saferpassUserRegistered;
    }

    /**
     * Get Configuration status for Password Manager App
     * @public
     * @memberof PasswordManagerService
     * @return {AppConfigurationStatus} CONFIGURED if user is registered (has master passowrd set) NOT_CONFIGURED otherwise
     */
    public getConfigurationStatus(): AppConfigurationStatus {
        return this.appConfigurationStatus;
    }
}
