import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { concat, last, map, Observable, of } from "rxjs";
import { AppsConfigService } from "../../config/apps.config.service";
import { NavigationService } from "../../navigation/navigation.service";
import { ProductsToInstall, ValuesService } from "../../values/values.service";
import { AdobeDataLayerService } from "../core/adobe.datalayer.service";
import { QueryParamsService } from "../core/query.params.service";
import { UsefulService } from "../global/useful/useful.service";
import { UrlProperties } from "./models/UrlProperties.model";


@Injectable({
    providedIn: 'root'
})
export class NavigationPublicGuard implements CanActivate {

    constructor(
        @Inject(DOCUMENT) readonly document: any,
        private router: Router,
        private readonly navigationService: NavigationService,
        private readonly queryParamsService: QueryParamsService,
        private readonly valuesService: ValuesService,
        private readonly adobeDataLayerService: AdobeDataLayerService,
        private readonly appsConfigService: AppsConfigService,
        private readonly usefulService: UsefulService
    ) { }

    private _processDownloadSpecialCases(urlProperties: UrlProperties): Observable<UrlTree|boolean> {
        return new Observable( subscriber => {
            let failToDownloadpasswordManagerSfr = () => {
                return !!(!this.appsConfigService.showApp(this.valuesService.appPassManagerSfr)
                    && urlProperties.queryObject && urlProperties.queryObject[this.valuesService.queryParams.product]
                    && urlProperties.queryObject[this.valuesService.queryParams.product] === ProductsToInstall.PASSWORDMANAGERSFR);
            }
        
            let failToDownloadpasswordManager = () => {
                return !!(!this.appsConfigService.showApp(this.valuesService.appPassManager)
                    && urlProperties.queryObject && urlProperties.queryObject[this.valuesService.queryParams.product]
                    && urlProperties.queryObject[this.valuesService.queryParams.product] === ProductsToInstall.PASSWORDMANAGER);
            }
        
            let failToDownloadProduct = () => {
                return urlProperties?.queryObject?.product && !this.appsConfigService.showDownloadedProductDeployFlow(urlProperties?.queryObject?.product);
            }
    
            if (failToDownloadpasswordManager() || failToDownloadpasswordManagerSfr() || failToDownloadProduct()) {
                let tempQueryParams = JSON.parse(JSON.stringify(urlProperties.queryObject));
                delete tempQueryParams[this.valuesService.queryParams.product];
                delete tempQueryParams[this.valuesService.queryParams.installCode];
                const newQueryString = this.usefulService.jsonToQueryString(tempQueryParams);
                const redirect = urlProperties.entirePath.concat('?', newQueryString);
                const emitedValue = this.router.parseUrl(`/${redirect}`);
                subscriber.next(emitedValue);
                subscriber.complete()
                return;
            } else {
                subscriber.next(true);
                subscriber.complete()
                return;
            }
        })
    }

    /**
     * Function that creates an URL Tree without query params
     * @param {UrlProperties} urlProperties Object that contains all information about URL
     * @returns {Observable<boolean|UrlTree>} True when no query  params should be cleaned / URL Tree with no query params
     */
    private _cleanQueryParams(urlProperties: UrlProperties): Observable<boolean|UrlTree> {
        return of(this.queryParamsService.getCleanedRouteOfCleanQueryParams(urlProperties));
    }

    /**
     * Saves all query params from URL into buffer or storage or both
     * @param {UrlProperties} urlProperties Object that contains all information about URL
     * @returns {Observable} Success when everything finished
     */
    private _saveAllQueryParams (urlProperties: UrlProperties): Observable<boolean|UrlTree> {
        return new Observable( subscriber => {
            this.queryParamsService.saveQueryParamsPersistently(urlProperties.queryObject);
            subscriber.next(true);
            subscriber.complete()
            return;
        })
    }

    /**
     * Contains the requests necessary for deciding the final user interface and redirect
     * All entries in concats are put in order of priorities. Only one path redirect and/or modal redirect should be made.
     *
     * @private
     * @param {*} route
     * @param {*} state
     * @return {*}  {(Observable<boolean|UrlTree>)}
     */
    private processGuardActions(route, state): Observable<boolean|UrlTree> {
        return new Observable( subscriber => {
            const urlProperties = this.navigationService.processUrl(route, state);
            concat(
                this._processDownloadSpecialCases(urlProperties),
                this._saveAllQueryParams(urlProperties),
                this._cleanQueryParams(urlProperties)
            )
            .pipe(
                map( finalRoute => {
                    if (finalRoute !== true) {
                        subscriber.next(finalRoute);
                        subscriber.complete();
                        throw Error('EMPTY_RESPONSE');
                    }
                }),
                last()
            ).subscribe({
                next: () => {
                    this.adobeDataLayerService.setTrackingId(this.queryParamsService.getAll());
                    subscriber.next(true);
                    subscriber.complete();
                },
                error: (err) => {
                    //> This has the role to break concat and not display error in console.
                }
            })
        })
    }

    /**
     * Guard entrypoint
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        return this.processGuardActions(route, state);
    }
}