import { BehaviorSubject } from "rxjs";

export interface IPrivacyFootprintServiceState {
    [key: string]: BehaviorSubject<string>;
}

export interface IMarkToUpdateFootprintServiceState {
    [key: string]: boolean;
}

export enum IPrivacyFootPrintIssuesType {
    BREACH = 'breach',
    EXPOSURE = 'exposure',
}

export enum IPrivacyFootprintServiceStatus {
    inUse = 'in_use',
    remediation = 'remediation',
    reviewed = 'reviewed'
}

export const PersonalFootprintMaxCategories = 3;

export enum ServiceStatusEnum {
    MALWARE = 'malware',
    MALWARE_HD = 'malware-hd',
    PHISHING = 'phishing',
    FRAUD = 'fraud',
    UNTRUSTED = 'untrusted',
    SPAM = 'spam',
    PUA = 'pua',
    MINER = 'miner',
    HOMOGRAPH = 'homograph',
    CC = 'c&c'
}

