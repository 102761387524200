// External
import { Injectable } from '@angular/core';

//  Internal
import {
    WebmailProtectionInboxActions,
    WebmailProtectionButtons,
    WebmailProtectionButtonTypes,
    WebmailProtectionScanDescriptions,
    WebmailProtectionScanDescriptionStatus,
    WebmailProtectionSummaryTooltip,
    WebmailProtectionLoadingDescription
} from '../models/security/WebmailProtection.model';
import { ThreatTypes } from '../models/Threats.model';
import { Security } from '../models/security/Security.model';
import { ChartStyle } from '../models/Chart.model';

@Injectable({
    providedIn: 'root'
})

export class WebmailProtectionValuesService {

    public readonly allWebmailProtectionThreatTypes: ThreatTypes[] = [
        ThreatTypes.MALWARE,
        ThreatTypes.PHISHING,
        ThreatTypes.FRAUD,
        ThreatTypes.PUA,
        ThreatTypes.RANSOMWARE,
        ThreatTypes.CERTIFICATE,
        ThreatTypes.UNTRUSTED,
        ThreatTypes.EXPLOIT,
        ThreatTypes.ATTACK,
        ThreatTypes.PRIVACY,
        ThreatTypes.ANOMALY,
        ThreatTypes.BOT,
        ThreatTypes.SPAM,
        ThreatTypes.MINER,
        ThreatTypes.TROJAN,
        ThreatTypes.MALICIOUS_HOST
    ];

    public readonly tooltipSummaryTexts: WebmailProtectionSummaryTooltip[] = [
        {
            text: 'security.webmailprotection.activity.tooltip.safe.emails',
            hasThreats: false
        },
        {
            text: 'security.webmailprotection.activity.tooltip.dangerous.emails',
            hasThreats: true
        }
    ];

    public readonly tooltipThreatsTexts: Security.EmailProtection.TooltipTexts = {
        [ThreatTypes.MALWARE]: 'security.webmailprotection.activity.tooltip.threat.malware',
        [ThreatTypes.PHISHING]: 'security.webmailprotection.activity.tooltip.threat.phishing',
        [ThreatTypes.FRAUD]: 'security.webmailprotection.activity.tooltip.threat.fraud',
        [ThreatTypes.PUA]: 'security.webmailprotection.activity.tooltip.threat.pua',
        [ThreatTypes.RANSOMWARE]: 'security.webmailprotection.activity.tooltip.threat.ransomware',
        [ThreatTypes.CERTIFICATE]: 'security.webmailprotection.activity.tooltip.threat.certificate',
        [ThreatTypes.UNTRUSTED]: 'security.webmailprotection.activity.tooltip.threat.untrusted',
        [ThreatTypes.EXPLOIT]: 'security.webmailprotection.activity.tooltip.threat.exploit',
        [ThreatTypes.ATTACK]: 'security.webmailprotection.activity.tooltip.threat.attack',
        [ThreatTypes.PRIVACY]: 'security.webmailprotection.activity.tooltip.threat.privacy',
        [ThreatTypes.ANOMALY]: 'security.webmailprotection.activity.tooltip.threat.anomaly',
        [ThreatTypes.BOT]: 'security.webmailprotection.activity.tooltip.threat.bot',
        [ThreatTypes.SPAM]: 'security.webmailprotection.activity.tooltip.threat.spam',
        [ThreatTypes.MINER]: 'security.webmailprotection.activity.tooltip.threat.miner',
        [ThreatTypes.TROJAN]: 'security.webmailprotection.activity.tooltip.threat.trojan',
        [ThreatTypes.MALICIOUS_HOST]: 'security.webmailprotection.activity.tooltip.threat.malicious_host'
    };

    public readonly sideMenuWidth = 280;
    public readonly chartMaxValue = 30;
    public readonly chartAxisMaxValues: Security.EmailProtection.ChartMaxValues = {
        yAxisValue: 100,
        xAxisValue: 30
    };

    public readonly chartStyles: ChartStyle = {
        barColors: {
            empty: '#0000000D',
            safe: '#44AA44',
            dangerous: '#EA0220'
        },
        ticks: {
            padding: 16,
            maxRotation: 0,
            autoSkipPadding: 8,
            beginAtZero: true
        },
        gridLines: {
            drawTicks: true,
            drawBorder: false,
            tickMarkLength: 0,
            color: '#EDEDF0',
            borderDash: [4],
            zeroLineBorderDash: [4],
            zeroLineColor: '#EDEDF0'
        }
    };

    public readonly webmailProtectionBenefits: string[] = [
        'security.webmailprotection.benefits.protection',
        'security.webmailprotection.benefits.configuration',
        'security.webmailprotection.benefits.support'
    ];

    public readonly webmailProtectionButtons: WebmailProtectionButtons = {
        [WebmailProtectionButtonTypes.PAUSE_ACTIVITY]: {
            type: WebmailProtectionButtonTypes.PAUSE_ACTIVITY,
            buttonName: 'security.webmailprotection.email.accounts.button.pause',
            buttonAction: 'webmail.pause.activity',
            icon: 'pause',
            iconType: 'general',
            color: 'warning-20'
        },
        [WebmailProtectionButtonTypes.RESUME_ACTIVITY]: {
            type: WebmailProtectionButtonTypes.RESUME_ACTIVITY,
            buttonName: 'security.webmailprotection.email.accounts.button.resume',
            buttonAction: 'webmail.resume.activity',
            icon: 'resume',
            iconType: 'general',
            color: 'primary'
        },
        [WebmailProtectionButtonTypes.REMOVE_EMAIL]: {
            type: WebmailProtectionButtonTypes.REMOVE_EMAIL,
            buttonName: 'security.webmailprotection.email.accounts.button.remove',
            buttonAction: 'webmail.remove.email',
            icon: 'remove',
            iconType: 'feedback',
            color: 'danger'
        }
    };

    public readonly webmailProtectionLoadingDescription: WebmailProtectionLoadingDescription = {
        [WebmailProtectionButtonTypes.PAUSE_ACTIVITY]: 'security.webmailprotection.email.accounts.pausing',
        [WebmailProtectionButtonTypes.RESUME_ACTIVITY]: 'security.webmailprotection.email.accounts.activating',
        [WebmailProtectionButtonTypes.REMOVE_EMAIL]: 'security.webmailprotection.email.accounts.removing'
    };

    webmailProtectionScanStatus: WebmailProtectionScanDescriptions = {
        [WebmailProtectionScanDescriptionStatus.ACTIVE]: {
            text: 'security.webmailprotection.email.accounts.status.active',
            icon: 'shield-check',
            iconType: 'feedback',
            color: 'primary'
        },
        [WebmailProtectionScanDescriptionStatus.PAUSED]: {
            text: 'security.webmailprotection.email.accounts.status.paused',
            icon: 'v1-shield-pause',
            iconType: 'feature',
            color: 'warning-20'
        }
    };

    webmailProtectionInboxActions = {
        [WebmailProtectionInboxActions.REGISTERED]: {
            text: 'security.webmailprotection.email.accounts.activity.activated',
            icon: 'shield-check',
            iconType: 'feedback',
            color: 'primary'
        },
        [WebmailProtectionInboxActions.PAUSED]: {
            text: 'security.webmailprotection.email.accounts.activity.paused',
            icon: 'v1-shield-pause',
            iconType: 'feature',
            color: 'warning-20'
        },
        [WebmailProtectionInboxActions.REMOVED]: {
            text: 'security.webmailprotection.email.accounts.activity.removed',
            icon: 'shield-x',
            iconType: 'feedback',
            color: 'danger'
        },
        [WebmailProtectionInboxActions.RESUMED]: {
            text: 'security.webmailprotection.email.accounts.activity.resumed',
            icon: 'shield-check',
            iconType: 'feedback',
            color: 'primary'
        }
    };

    public readonly omnitureEvents = {
        removeInbox: 'remove-inbox',
        updateInbox: 'update-inbox',
        blogFromNews: 'blog-from-news',
        blogFromThreat: 'blog-from-threat',
        installOnMoreDevices: 'install-on-more-devices'
    };

    public readonly overviewPageCount = 10;

    public readonly weekTooltip: Security.Overview.WeekTooltip = {
        title: 'security.threats.label.week',
        moreThreats: 'security.threats.tooltip.week.more',
        oneThreat: 'security.threats.tooltip.week.one'
    };
}
